import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DateTime } from 'luxon';
import { SubmitHandler } from 'react-hook-form';
import { useReactToPrint } from 'react-to-print';

import {
  MtmDriverManifestRead,
  useLazyTripsMtmDriverManifestListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { useLazyGetTripsExportAsCsvQuery } from 'src/common/external/bambi-api/emptyApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { Select, SelectOption } from 'src/common/primitives/Select';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { usePayerSelectData } from '../../../usePayerSelectData';
import { DetailsFormFields } from '../DetailsFormFields';
import { DetailsForm, useDetailsForm } from '../useDetailsForm';
import { PrintableDailyTripLog } from './PrintableDailyTripLog';

const reportTypeOptions: SelectOption[] = [
  {
    label: 'Trip upload report',
    value: 'trip-upload-report',
  },
  {
    label: 'Daily trip log',
    value: 'daily-trip-log',
  },
];

interface MTMDetailsExportProps {
  onClose: () => void;
}

export function MTMDetailsExport({ onClose }: MTMDetailsExportProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [selectedReport, setSelectedReport] = useState<string | undefined>(
    reportTypeOptions[0].value
  );
  const [renderPdf, setRenderPdf] = useState(false);
  const { handleSubmit, control } = useDetailsForm();

  const [trigger] = useLazyGetTripsExportAsCsvQuery();

  const { slugifyPayerName, payerData } = usePayerSelectData();
  const payer = useMemo(() => {
    if (!payerData) {
      return null;
    }

    return payerData.results.find(
      (p) => p.payer_info_name?.toLowerCase() === 'mtm'
    );
  }, [payerData]);

  const handleDownloadTrips = useCallback<SubmitHandler<DetailsForm>>(
    async (fields) => {
      if (!payer) {
        setError('Unable to find MTM payer');
        return;
      }

      try {
        setError(undefined);
        setLoading(true);

        const payerInfoName = payer.payer_info_name;
        const payerDisplayName = payer.display_name;
        const outputSet = slugifyPayerName(payerInfoName ?? payerDisplayName);

        // Trigger will cause a blob download, see emptyApi
        await trigger({
          rangeStart: fields.startDate?.startOf('day').toISO(),
          rangeEnd: fields.endDate?.endOf('day').toISO(),
          outputSet,
          format: 'csv',

          payers: payer.id,
          passengers: '',
          externalTripIds: (fields.externalTripIdOptions ?? [])
            .map((option) => option.value)
            .join(','),
          tripIds: '',
        }).unwrap();
        onClose();
      } catch (e) {
        const errMsg = `There was an error downloading the data. ${formatServerError(
          e
        )}`;
        setError(errMsg);
        setLoading(false);
      }
    },
    [onClose, payer, slugifyPayerName, trigger]
  );

  const printRef = useRef<HTMLDivElement>(null);
  const triggerPrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `MTM Daily Trip Log ${DateTime.now().toFormat(
      'yyyy-MM-dd'
    )}`,
    onAfterPrint: onClose,
  });

  const [triggerDownloadMTMDailyLogTrips] =
    useLazyTripsMtmDriverManifestListQuery();
  const [manifestList, setManifestList] = useState<MtmDriverManifestRead[]>([]);

  const handleRenderPdf = useCallback<SubmitHandler<DetailsForm>>(
    async (fields) => {
      setManifestList([]);
      setError(undefined);
      setLoading(true);
      try {
        const { results } = await triggerDownloadMTMDailyLogTrips({
          rangeStart: fields.startDate?.startOf('day').toISO() ?? '',
          rangeEnd: (fields.endDate || DateTime.now()).endOf('day').toISO(),
        }).unwrap();

        if (!results.length) {
          setError('No results found for given date range');
          setLoading(false);
          return;
        }

        setManifestList(results);
        setRenderPdf(true);
      } catch (e) {
        const errMsg = `There was an error downloading the data. ${formatServerError(
          e
        )}`;
        setError(errMsg);
        setLoading(false);
      }
    },
    [triggerDownloadMTMDailyLogTrips]
  );

  // Trigger as an effect to allow the component to render before printing
  useEffect(() => {
    if (renderPdf) {
      triggerPrint();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderPdf]);

  if (renderPdf) {
    return (
      <>
        <PrintableDailyTripLog ref={printRef} manifestList={manifestList} />
        <LoadingIndicator />
      </>
    );
  }

  return (
    <div>
      <div className="flex flex-col gap-2">
        <h1 className="text-2xl">MTM Portal details download</h1>
        <p className=" text-gray-600">
          Downloaded fields are determined by the type of report.
        </p>
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
        <div className="mb-2">
          <div>Select the report type</div>
          <Select
            options={reportTypeOptions}
            onChange={(val) => {
              setSelectedReport(val?.value);
            }}
            value={selectedReport}
          />
        </div>
        <DetailsFormFields
          control={control}
          includeDateRange
          includeExternalTripIds={selectedReport === 'trip-upload-report'}
        />
        <div className="flex flex-row justify-between gap-4 pt-6">
          <div>
            <Button disabled={loading} onClick={onClose}>
              Cancel
            </Button>
          </div>
          <div className="flex flex-row gap-2">
            {selectedReport === 'daily-trip-log' && (
              <Button
                dataTestId="download-pdf"
                disabled={loading}
                loading={loading}
                variant="primary"
                onClick={handleSubmit(handleRenderPdf)}
              >
                {loading ? 'Exporting' : 'Print to PDF'}
              </Button>
            )}
            {selectedReport !== 'daily-trip-log' && (
              <Button
                dataTestId="download-csv"
                disabled={loading}
                loading={loading}
                variant="primary"
                onClick={handleSubmit(handleDownloadTrips)}
              >
                {loading ? 'Exporting' : 'Export as CSV'}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
