/**
 *
 * @param plate
 * @param state 2 letter state code
 * @returns boolean | string describing the error
 *
 * Light validation for US only including vanity plates
 */
export function isValidUSALicensePlate(
  plate: string,
  state?: string | null
): boolean | string {
  const cleanPlate = plate.trim().toUpperCase().replace(/\s+/g, '');
  if (!cleanPlate) return true;
  if (cleanPlate.length === 1) return 'Plate too short';

  const patternKey = state && state in stateValidations ? state : 'default';
  const pattern = stateValidations[patternKey];
  if (!pattern.test(cleanPlate)) return `Invalid plate format`;
  // No more than 1 hyphen
  if (/-{2,}/.test(cleanPlate)) return `Invalid plate format`;
  return true;
}

// State-specific validation patterns (including vanity plate support)
const stateValidations: Record<string, RegExp> = {
  CA: /^[a-zA-Z0-9-]{2,7}$/,
  NY: /^[a-zA-Z0-9-]{2,7}$/,
  TX: /^[a-zA-Z0-9-]{2,7}$/,
  FL: /^[a-zA-Z0-9-]{2,7}$/,
  IL: /^[a-zA-Z0-9-]{2,7}$/,
  // Delaware is only numeric plates and has disallowed vanity plates BUT existing vanity plates can still be renewed
  default: /^[a-zA-Z0-9-]{2,8}$/,
};
