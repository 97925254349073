import { PassengerRead } from 'src/common/external/bambi-api/bambiApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { FormField } from 'src/common/FormField';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { FormFieldSwitch } from 'src/common/FormField/FormFieldSwitch';
import { ManagePaymentMethods } from 'src/common/forms/PaymentMethodCaptureForm/ManagePaymentMethods';
import { useCanCapturePaymentMethod } from 'src/common/forms/PaymentMethodCaptureForm/useCanCapturePaymentMethod';
import { FormSubmitButton } from 'src/common/FormSubmitButton';
import { MultiSelectPayerField } from 'src/common/MultiSelectPayerContextField';
import { Button } from 'src/common/primitives/Button';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { Modal } from 'src/common/primitives/Modal';
import { PhoneInput } from 'src/common/primitives/PhoneInput';
import { Select } from 'src/common/primitives/Select';
import { useIsRideOrderingPortalEnabledForOrganization } from 'src/features/rideOrderingPortal/useIsRideOrderingPortalEnabledForOrganization';
import { useShouldShowRideOrderingPortalFeatures } from 'src/features/rideOrderingPortal/useShouldShowRideOrderingPortalFeatures';

import AllowSMSFormContextField from './AllowSMSFormContextField';
import { DeleteAction } from './DeleteAction';
import { FormValues } from './FormValues';

const sexOptions = [
  {
    label: 'Select an option',
    value: '',
  },
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export function AddEditModal({
  open,
  setOpen,
  onClose,
  onCancel,
  onSubmit,
  onDelete,
  passenger,
  setValue,
  loading,
  serverErrors,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose: () => void;
  onCancel: () => void;
  onSubmit: () => void;
  onDelete: () => void;
  passenger?: PassengerRead;
  setValue?: (name: keyof FormValues, value: any) => void;
  loading: boolean;
  serverErrors: string | null;
}) {
  const shouldShowRideOrderingPortalFeatures =
    useShouldShowRideOrderingPortalFeatures();
  const isRideOrderingPortalEnabledForOrganization =
    useIsRideOrderingPortalEnabledForOrganization();
  const {
    isEnabled: hasPaymentCaptureFeature,
    isLoading: isLoadingPaymentCaptureFeature,
  } = useCanCapturePaymentMethod();

  const isEditing = !!passenger?.id;
  return (
    <Modal open={open} setOpen={setOpen} onClose={onClose}>
      <form onSubmit={onSubmit} data-testid="passenger-form">
        <div>
          <h3 className="text-[24px] font-medium leading-6 text-gray-900">
            {passenger?.id ? 'Edit Passenger' : 'Add a New Passenger'}
          </h3>
          <p className="mt-1 text-xs text-gray-500">
            {passenger?.id ? 'Edit' : 'Create a'} profile for the new passenger.
            You will be able to edit these details whenever after you save it.
          </p>
          <p className="mt-2 text-xs text-gray-500">*Required</p>
        </div>

        {!!serverErrors && <FormErrorMessage>{serverErrors}</FormErrorMessage>}

        <div className="mt-4">
          <div>
            <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <FormFieldController name="first_name">
                  {({ field, fieldState }) => {
                    return (
                      <>
                        <FormField
                          label="Name *"
                          error={fieldState.error?.message?.toString()}
                          type="text"
                          inputProps={{
                            id: 'first-name',
                            placeholder: 'First Name',
                            ...field,
                          }}
                        />
                      </>
                    );
                  }}
                </FormFieldController>
              </div>

              <div className="mt-5 sm:col-span-3">
                <FormFieldController name="last_name">
                  {({ field, fieldState }) => {
                    return (
                      <FormField
                        error={fieldState.error?.message?.toString()}
                        type="text"
                        inputProps={{
                          id: 'last-name',
                          placeholder: 'Last Name',
                          ...field,
                        }}
                      />
                    );
                  }}
                </FormFieldController>
              </div>
              <div className="mt-4 sm:col-span-3">
                <FormFieldController name="phone_number">
                  {({ field, fieldState }) => {
                    return (
                      <DefaultFormFieldLayout
                        label="Phone number"
                        error={fieldState.error?.message?.toString()}
                        inputProps={{ id: 'phone' }}
                      >
                        <PhoneInput
                          {...field}
                          value={field.value}
                          dataTestId="phone_number"
                        />
                      </DefaultFormFieldLayout>
                    );
                  }}
                </FormFieldController>
              </div>
              <div className="mt-4 sm:col-span-3">
                <AllowSMSFormContextField />
              </div>
              <div className="mt-4 sm:col-span-3">
                <FormFieldController name="email">
                  {({ field, fieldState }) => {
                    return (
                      <FormField
                        label="Email address"
                        error={fieldState.error?.message?.toString()}
                        type="email"
                        inputProps={{
                          id: 'email',
                          placeholder: 'Email Address',
                          ...field,
                        }}
                      />
                    );
                  }}
                </FormFieldController>
              </div>
              <div className="mt-4 sm:col-span-3">
                <FormFieldController name="dob">
                  {({ field, fieldState }) => {
                    return (
                      <FormField
                        label="Date of Birth"
                        error={fieldState.error?.message?.toString()}
                        type="date"
                        inputProps={{
                          id: 'date-of-birth',
                          placeholder: 'Select a date',
                          ...field,
                        }}
                      />
                    );
                  }}
                </FormFieldController>
              </div>
              <div className="mt-4 sm:col-span-3">
                <FormFieldController name="gender">
                  {({ field, fieldState }) => {
                    return (
                      <DefaultFormFieldLayout
                        label="Sex"
                        error={fieldState.error?.message?.toString()}
                        inputProps={{ id: 'gender' }}
                      >
                        <Select
                          testIdSuffix="gender"
                          options={sexOptions}
                          {...field}
                          onChange={(selected) => {
                            if (selected && setValue) {
                              setValue('gender', selected.value);
                            }
                          }}
                        />
                      </DefaultFormFieldLayout>
                    );
                  }}
                </FormFieldController>
              </div>
              {/* Used to align weight with bariatric toggle */}
              <div className="mt-4 sm:col-span-3">&nbsp;</div>
              <div className="mt-4 sm:col-span-3">
                <FormFieldController name="weight">
                  {({ field, fieldState }) => {
                    return (
                      <FormField
                        label="Weight"
                        error={fieldState.error?.message?.toString()}
                        type="number"
                        inputProps={{
                          id: 'weight',
                          placeholder: 'Enter weight',
                          onChange: (e) => {
                            field.onChange(parseInt(e.target.value, 10));
                          },
                          value: field.value,
                        }}
                      />
                    );
                  }}
                </FormFieldController>
              </div>
              <div className="mt-4 sm:col-span-3">
                <FormFieldController name="needs_bariatric_transport">
                  {({ field, fieldState }) => {
                    return (
                      <FormFieldSwitch
                        label="Bariatric?"
                        error={fieldState.error?.message?.toString()}
                        inputProps={{
                          id: 'needs-bariatric',
                          ...field,
                          checked: field.value,
                        }}
                      />
                    );
                  }}
                </FormFieldController>
              </div>
              {!shouldShowRideOrderingPortalFeatures && (
                <div className="mt-4 sm:col-span-6">
                  <FormFieldController name="notes">
                    {({ field, fieldState }) => {
                      return (
                        <FormField
                          label="Additional Notes (optional, visible to drivers)"
                          error={fieldState.error?.message?.toString()}
                          type="textarea"
                          inputProps={{
                            id: 'notes',
                            ...field,
                          }}
                        />
                      );
                    }}
                  </FormFieldController>
                </div>
              )}
              {!shouldShowRideOrderingPortalFeatures && (
                <div className="mt-4 sm:col-span-6">
                  <FormFieldController name="dispatcher_notes">
                    {({ field, fieldState }) => {
                      return (
                        <FormField
                          label="Dispatcher Notes (optional, NOT visible to drivers)"
                          error={fieldState.error?.message?.toString()}
                          type="textarea"
                          inputProps={{
                            id: 'dispatcher_notes',
                            ...field,
                          }}
                        />
                      );
                    }}
                  </FormFieldController>
                </div>
              )}
              {
                // Currently, we only add passengers through the trip form, so this is only needed when editing
                // There's some backend work on the /passengers/ POST endpoint to accept payer_ids. Hiding this
                // lets us punt that til we want to add passenger creation to the passenger list page
                isEditing && isRideOrderingPortalEnabledForOrganization && (
                  <div className="mt-4 max-w-[30rem] sm:col-span-6">
                    <MultiSelectPayerField fieldPath="payer_ids" />
                  </div>
                )
              }
              {isLoadingPaymentCaptureFeature ? (
                <LoadingIndicator testId="loading-payment-capture" />
              ) : (
                hasPaymentCaptureFeature && (
                  <div className="mt-4 sm:col-span-6">
                    <FormFieldController name="billing_details">
                      {({ field }) => {
                        return (
                          <ManagePaymentMethods
                            payerId={passenger?.id ?? ''}
                            payerType="passenger"
                            billingName={field.value?.billingName}
                            onChangeBillingDetails={field.onChange}
                          />
                        );
                      }}
                    </FormFieldController>
                  </div>
                )
              )}
            </div>
          </div>
          {passenger?.id ? <DeleteAction onDelete={onDelete} /> : null}
          <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <Button
              className="sm:col-span-3"
              onClick={onCancel}
              disabled={loading}
            >
              Cancel
            </Button>
            <FormSubmitButton
              className="sm:col-span-3"
              buttonName="Save"
              isSubmitting={loading}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
}
