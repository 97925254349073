// See comment in tailwind.config.js
// This has been duplicated there to sidestep figuring out module resolution for now
const colors = {
  mint: '#0CACA6',
  'mint-light': 'rgba(38, 204, 198, 0.25)',
  indigo: '#1D3557',
  orange: {
    50: '#FEF6EE',
    700: '#B93815',
  },
  pink: {
    50: '#FDF2FA',
    700: '#C11574',
  },
  blue: {
    50: '#EFF8FF',
    700: '#175CD3',
  },
  'daybreak-blue': {
    600: '#1890FF',
  },
  'blue-light': {
    50: '#F0F9FF',
    700: '#026AA2',
  },
  'blue-dark': {
    800: '#0078BB',
  },
  primary: {
    50: '#F9F5FF',
    700: '#6941C6',
  },
  success: {
    50: '#ECFDF3',
    200: '#cdece4',
    500: '#12B76A',
    700: '#027A48',
  },
  warning: {
    25: '#FFFCF5',
    50: '#fffbeb',
    300: '#FEC84B',
    600: '#DC6803',
    700: '#B54708',
  },
  error: {
    50: '#FEF3F2',
    100: '#FEE4E2',
    500: '#F04438',
    700: '#B42318',
  },
  /**
   * TODO: type this correctly and convert this to TS
   * Something like
   *
   * type SpaceTypeColorKeys = {
   *  [K in SpaceTypeEnum]: {[x: string]: string}
   * }
   */
  'space-type': {
    wheelchair: { primary: '#FFB4D3' },
    stretcher: { primary: '#BCD3FF' },
    ambulatory: { primary: '#CC8BFF' },
  },
  trip: {
    scheduled: {
      bg: '#C4E3FF',
      border: '#00466B',
      text: '#00466B',
    },
    active: {
      bg: '#c3ffbe',
      border: '#075A3E',
      text: '#1C5D04',
    },
    complete: {
      bg: '#EAECF0',
      border: '#3F4B5A',
      text: '#474E5C',
      bullet: 'rgba(102,112,133,1)',
    },
    requested: {
      bg: '#F0F9FF',
      border: '#E0F2FE',
      text: '#E0F2FE',
    },
    willcall: {
      bg: '#efd2ff',
      border: '#3B204C',
      text: '#3B204C',
    },
    canceled: {
      bg: '#F3D3D1',
      border: '#880606',
      text: '#7C1A13',
      bullet: 'rgba(188,10,10,1)',
    },
    delayed: {
      bg: '#fff3cd',
      border: '#674E04',
      text: '#674E04',
    },
    newAssignment: {
      bg: '#0078BB',
      border: '#FFF',
      text: '#FFF',
    },
    reassignment: {
      bg: '#6941C6',
      border: '#FFF',
      text: '#FFF',
    },
  },
};

export default colors;
