import { OverallPaymentStatusEnum } from 'src/common/external/bambi-api/bambiApi';
import { Tag } from 'src/common/Tag/Tag';

import { TagColumnWrapper } from './TagColumnWrapper';
import { usePaymentStatusToLabel } from './useStatusToLabel';

interface PaymentStatusCellProps {
  status: OverallPaymentStatusEnum;
  anyPaymentsProcessing: boolean;
  hasManualPayment: boolean;
}
export function PaymentStatusCell({
  status,
  anyPaymentsProcessing,
  hasManualPayment,
}: PaymentStatusCellProps) {
  const result = usePaymentStatusToLabel(status);

  let tagContent: JSX.Element | null = <Tag {...result} />;

  if (anyPaymentsProcessing) {
    tagContent = <Tag color="#c1d8ee" label="Processing" />;
  } else if (status === 'unknown') {
    tagContent = null;
  }

  if (hasManualPayment) {
    tagContent = (
      <>
        {tagContent}
        <Tag color="#aaa" label="Has Manual Payment" />
      </>
    );
  }
  return <TagColumnWrapper>{tagContent}</TagColumnWrapper>;
}
