import { truncate } from 'lodash-es';

import { PayerOrganizationRead } from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';

export default function addDefaultTripAddressOption(
  options: SelectOption[],
  selectedPayer: PayerOrganizationRead | null | undefined
) {
  if (selectedPayer?.default_trip_address?.location?.place_id) {
    const defaultTripAddress = selectedPayer.default_trip_address.location;
    const truncatedPayerName = truncate(selectedPayer.display_name, {
      length: 20,
    });
    const defaultTripAddressOption = {
      label: `(${truncatedPayerName}) ${defaultTripAddress.label}`,
      value: defaultTripAddress.place_id,
    };
    if (
      !options.find((option) => option.value === defaultTripAddressOption.value)
    ) {
      options.push(defaultTripAddressOption);
    }
  }
  return options;
}
