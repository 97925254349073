import { useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { FormField } from 'src/common/FormField';
import { FormFieldController } from 'src/common/FormField/FormFieldController';

import ConfirmPayerDeactivationModal from '../ConfirmPayerDeactivationModal';

export default function PayerActiveToggleField() {
  const { setValue, getValues } = useFormContext();
  const { id } = getValues();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onCancelDeactivation = () => {
    setValue('is_active', true);
    setShowConfirmModal(false);
  };

  return (
    <>
      <FormFieldController name="is_active">
        {({ field, fieldState }) => {
          return (
            <>
              <FormField
                label="Is this payer active?"
                error={fieldState.error?.message?.toString()}
                type="switch"
                inputProps={{
                  id: 'is_active',
                  onChange: (value) => {
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    if (!value && id) {
                      setShowConfirmModal(true);
                    }
                    field.onChange(value);
                  },
                  value: '',
                  checked: field.value,
                }}
              />
            </>
          );
        }}
      </FormFieldController>
      <ConfirmPayerDeactivationModal
        open={showConfirmModal}
        onConfirm={() => setShowConfirmModal(false)}
        onCancel={onCancelDeactivation}
      />
    </>
  );
}
