import {
  PaginatedPassengerAddressListRead,
  PayerOrganizationRead,
} from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';

import addDefaultTripAddressOption from './addDefaultTripAddressOption';
import getRecentAddressesAsOptions from './getRecentAddressesAsOptions';

export const useAdditionalResultsQuery = (
  recentAddressResponse: PaginatedPassengerAddressListRead | undefined,
  selectedPayer: PayerOrganizationRead | null | undefined
) => {
  const additionalResultsQueryFn = async (
    query: string,
    options: SelectOption[]
  ) => {
    let additionalOptions: SelectOption[] = getRecentAddressesAsOptions(
      recentAddressResponse
    );

    additionalOptions = addDefaultTripAddressOption(
      additionalOptions,
      selectedPayer
    );

    return Promise.resolve([
      ...additionalOptions.filter((option) =>
        option.label.toLowerCase().includes(query.toLowerCase())
      ),
      ...options.filter((option) => {
        const recentAddress = recentAddressResponse?.results.find(
          (result) => result.address.location?.place_id === option.value
        );
        return !recentAddress;
      }),
    ]);
  };

  return additionalResultsQueryFn;
};
