export function RunBambiRunLoadingIndicator({ message }: { message: string }) {
  return (
    <div
      data-testid="loading-indicator"
      className="flex max-w-lg flex-col gap-5"
    >
      <div className="flex flex-col items-center justify-center gap-1">
        <div>
          <img
            className="aspect-square p-4"
            src={process.env.PUBLIC_URL + '/map-icon-loader.gif'}
            alt="Run Bambi Run loading indicator"
          />
        </div>
        <h1 className="text-xl text-gray-900">{message}</h1>
      </div>
      <div className="flex flex-col gap-y-2">
        <div>
          <h2 className="text-lg font-bold">What is Route Optimization?</h2>
          <p>
            Route optimization is the process of assigning trips and arranging
            stops in the most efficient way possible. Our system factors in
            locations, appointment times, traffic conditions, driver
            availability, and any special requirements to determine the best
            schedule for your fleet. The goal is to help you perform the maximum
            number of trips while minimizing total drive time.
          </p>
        </div>
        <div>
          <h2 className="text-lg font-bold">
            Why Might Some Routes Appear Counterintuitive?
          </h2>
          <p>
            While our optimization aims to reduce overall drive time and
            maximize service capacity, it sometimes creates schedules that are
            not immediately obvious. For example, a driver may travel out of
            their usual area if doing so enables another driver to handle more
            trips or significantly reduce unnecessary deadhead (empty) miles.
            The bigger-picture outcome is a more efficient fleet-wide schedule,
            even if one individual driver appears to take an "extra" detour.
          </p>
        </div>
      </div>
    </div>
  );
}
