import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { DateTime } from 'luxon';

import {
  TripEventEventTypeEnum,
  TripEventRead,
} from 'src/common/external/bambi-api/bambiApi';
import { MASReportRecord } from 'src/features/trip/management/download/details/mas/types';

import { styles } from '../styles';

export const eventTypeToStatusLabel: Record<TripEventEventTypeEnum, string> = {
  status_en_route: 'En Route',
  status_at_pickup: 'At Pickup',
  status_transporting: 'Transporting',
  status_at_dropoff: 'At Dropoff',
  completion: 'Completed',
  uncompletion: 'Uncompleted',
  cancellation: 'Cancelled',
  uncancellation: 'Uncancelled',
  accept: 'Accepted',
  assign: 'Assigned',
  unassign: 'Unassigned',
  lock: 'Locked',
  unlock: 'Unlocked',
  rejection: 'Rejected',
  unrejection: 'Unrejected',
  activate_will_call: 'Activated Will Call',
  update_pickup_at: 'Updated Pickup At',
  push_needs_at_pickup: 'Pushed Needs At Pickup',
  push_needs_transporting: 'Pushed Needs Transporting',
  push_needs_dropoff: 'Pushed Needs Dropoff',
  push_needs_completed: 'Pushed Needs Completed',
  enter_pickup_geofence: 'Entered Pickup Geofence',
  exit_pickup_geofence: 'Exited Pickup Geofence',
  enter_dropoff_geofence: 'Entered Dropoff Geofence',
  exit_dropoff_geofence: 'Exited Dropoff Geofence',
  cancel_will_call_activation: 'Cancelled Will Call Activation',
};

type TripEventColumn = {
  header: string;
  cell: (e: TripEventRead) => string;
};

const tripEventColumns: TripEventColumn[] = [
  {
    header: 'Status',
    cell: (e) => eventTypeToStatusLabel[e.event_type],
  },
  {
    header: 'Time',
    cell: (e) => DateTime.fromISO(e.occurred_at).toFormat('MM/dd/yyyy HH:mm'),
  },
  {
    header: 'Latitude',
    cell: (e) => e.latitude?.toString() ?? '-',
  },
  {
    header: 'Longitude',
    cell: (e) => e.longitude?.toString() ?? '-',
  },
];

interface MASReportDocumentProps {
  trips: MASReportRecord[];
  mapData: Record<string, string | undefined>;
  companyName: string;
  companyLogoUrl?: string | null;
  tripEvents: Record<string, TripEventRead[] | undefined>;
}

/**
 * Renders the MAS report within the pdf document structure
 */
export function MASReportDocument({
  trips,
  mapData,
  companyName,
  companyLogoUrl,
  tripEvents,
}: MASReportDocumentProps) {
  return (
    <Document>
      {trips.map((trip, i) => {
        return (
          <Page
            size="A4"
            style={styles.page}
            key={trip.trip_id}
            orientation="landscape"
          >
            <View style={styles.pageHeader}>
              <View style={styles.companyInfoRow}>
                {companyLogoUrl ? (
                  <Image src={companyLogoUrl} style={styles.companyLogoImage} />
                ) : null}
                <Text style={styles.companyName}>{companyName}</Text>
              </View>
              <View>
                <Text>Internal Order ID: {trip.external_trip_id}</Text>
                <Text>
                  Date:{' '}
                  {DateTime.fromISO(trip.scheduled_pickup_at).toFormat(
                    'LL/dd/yyyy'
                  )}
                </Text>
              </View>
            </View>
            <View style={styles.section}>
              <View style={[styles.row, styles.darkBackground]}>
                <Text>PU Address: {trip.pickup_location_label}</Text>
                <Text>
                  Scheduled PU:{' '}
                  {DateTime.fromISO(trip.scheduled_pickup_at).toFormat('HH:mm')}
                </Text>
              </View>
              <View style={[styles.row, styles.lightBackground]}>
                <Text>DO Address: {trip.dropoff_location_label}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={[styles.section, styles.halfWidth]}>
                {mapData[trip.trip_id] ? (
                  <Image
                    source={mapData[trip.trip_id]}
                    style={styles.mapImage}
                  />
                ) : (
                  <Text style={styles.errorText}>
                    Unable to find map image for trip
                  </Text>
                )}
              </View>
              <View style={[styles.section, styles.halfWidth]}>
                <View style={[styles.attestationCell]}>
                  <Text style={styles.sectionTitle}>
                    Client - {trip.passenger_full_name}
                  </Text>
                  <Text style={styles.sectionTitle}>
                    CIN - {trip.member_id}
                  </Text>
                  <Text>DO Signature:</Text>
                  {trip.dropoff_passenger_signature_url ? (
                    <Image
                      style={styles.signatureImage}
                      source={trip.dropoff_passenger_signature_url}
                    />
                  ) : (
                    <Text style={styles.errorText}>
                      Missing passenger signature image
                    </Text>
                  )}
                </View>
                <View style={[styles.attestationCell]}>
                  <Text style={styles.sectionTitle}>
                    Driver - {trip.driver_name}
                  </Text>
                  <View style={[styles.row, styles.darkBackground]}>
                    <Text>
                      Driver License - {trip.driver_license_number}{' '}
                      {trip.driver_license_state}
                    </Text>
                  </View>
                  <View style={[styles.row, styles.darkBackground]}>
                    <Text>
                      Driver Attestation: By checking this attestation, I
                      understand that I am electronically signing that I affirm
                      that the information contained herein is true and accurate
                      and that I provided and completed the above services. In a
                      proceeding, evidence of a record or signature may not be
                      excluded solely because it is in electronic form.
                    </Text>
                  </View>
                  {trip.dropoff_driver_signature_url ? (
                    <Image
                      style={styles.signatureImage}
                      source={trip.dropoff_driver_signature_url}
                    />
                  ) : (
                    <Text style={styles.errorText}>
                      Missing driver signature image
                    </Text>
                  )}
                </View>
                <View style={[styles.attestationCell]}>
                  <Text style={styles.sectionTitle}>Vehicle</Text>
                  <View style={[styles.row, styles.darkBackground]}>
                    <Text>Internal Vehicle ID: {trip.vehicle_name}</Text>
                  </View>
                  <View style={[styles.row, styles.lightBackground]}>
                    <Text>Vehicle Type: {trip.space_type}</Text>
                  </View>
                  <View style={[styles.row, styles.darkBackground]}>
                    <Text>Plate: {trip.vehicle_plate}</Text>
                    <Text>VIN: {trip.vin}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.section}>
              <View style={styles.row}>
                {tripEventColumns.map((c) => {
                  return (
                    <View
                      key={c.header}
                      style={[
                        styles.alignLeft,
                        styles.quarterWidth,
                        styles.columnHeader,
                      ]}
                    >
                      <Text>{c.header}</Text>
                    </View>
                  );
                })}
              </View>
              {tripEvents[trip.trip_id]?.map((e) => {
                return (
                  <View style={[styles.row, styles.darkBackground]} key={e.id}>
                    {tripEventColumns.map((c) => {
                      return (
                        <View
                          key={c.header}
                          style={[styles.alignLeft, styles.quarterWidth]}
                        >
                          <Text>{c.cell(e)}</Text>
                        </View>
                      );
                    })}
                  </View>
                );
              })}
            </View>
          </Page>
        );
      })}
    </Document>
  );
}
