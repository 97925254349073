import { StyleSheet } from '@react-pdf/renderer';

// Create styles
export const styles = StyleSheet.create({
  // Layout/utility
  page: {
    flexDirection: 'column',
    padding: 10,
    fontSize: 12,
  },
  section: {
    marginTop: 5,
  },
  sectionTitle: {
    fontFamily: 'Helvetica-Bold',
  },
  pageHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  columnHeader: {
    fontFamily: 'Helvetica-Bold',
  },
  row: {
    flexDirection: 'row',
    padding: 5,
    justifyContent: 'space-between',
    marginBottom: 1,
  },
  alignLeft: {
    alignItems: 'flex-start',
  },
  darkBackground: {
    backgroundColor: '#E5E5E5',
  },
  lightBackground: {
    backgroundColor: '#F7F7F7',
  },
  halfWidth: {
    width: '50%',
  },
  quarterWidth: {
    width: '25%',
  },
  errorText: {
    color: 'red',
  },
  // Component styles
  signatureRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  signatureImage: {
    width: 50,
  },
  attestationCell: {
    margin: 5,
  },
  mapImage: {
    width: 400,
    height: 300,
  },
  companyInfoRow: {
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center',
  },
  companyLogoImage: {
    width: 100,
    height: 50,
  },
  companyName: {
    fontSize: 14,
  },
});
