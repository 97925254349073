import { Square3Stack3DIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import { usePayersListQuery } from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';
import { useAuth } from 'src/features/auth/useAuth';

import {
  DownloadCsvDetails,
  setDownloadCsvDetail,
  setShowDownloadDetailsModal,
  setShowDownloadModal,
} from '../../trip.slice';
import { isPayerEligibleForPayerPortalUploadExport } from './isPayerEligibleForPayerPortalUploadExport';

export function DownloadModal() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const open = useSelector((state: RootState) => state.trip.showDownloadModal);
  const downloadCsvDetail = useSelector(
    (state: RootState) => state.trip.downloadCsvDetail
  );

  const setOpen = (open: boolean) => {
    dispatch(setShowDownloadModal(open));
  };

  const setCsvDetail = (downloadDetail: DownloadCsvDetails) => {
    dispatch(setDownloadCsvDetail(downloadDetail));
  };

  const { data: payersList } = usePayersListQuery(
    {
      'Organization-ID': auth.currentOrganizationId || '',
    },
    {
      skip: !open,
    }
  );

  const downloadPayersList = payersList?.results
    ? payersList.results.filter(isPayerEligibleForPayerPortalUploadExport)
    : [];

  const radioData = [
    {
      text: 'All Trips Details',
      description: 'This provides every data field available for each trip',
      value: DownloadCsvDetails.ALL,
    },
  ];

  downloadPayersList.length > 0 &&
    radioData.push({
      text: 'For a Payer Portal Upload',
      description: "This is for uploading to a selected payer's portal",
      value: DownloadCsvDetails.PAYER,
    });

  /**
   * Below is a custom feature developed specifically for Careavan. At present
   * we don't want other organizations to use this feature, so we've decided to
   * not include it as a feature flag.
   * - Camden 2024-01-18
   */
  const canShowEverTransitExport = [
    // Careavan PROD ID
    '01878fe1-ac9f-a038-dd93-27cb223ae361',
    // Staging stagers staging ID
    '0184a13b-c765-5333-4d61-a6d4cc9ef60b',
  ].find((id) => id === auth.currentOrganizationId);

  if (canShowEverTransitExport) {
    radioData.push({
      text: 'For Evertransit',
      description: 'This is for uploading to Evertransit',
      value: DownloadCsvDetails.EVERTRANSIT,
    });
  }

  const hasKinetik = !!downloadPayersList.find((p) => p.has_kinetik_account);
  const hasMTM = !!downloadPayersList.find(
    (p) => p.payer_info_name?.toLowerCase() === 'mtm'
  );
  const hasMAS = !!downloadPayersList.find(
    (p) => p.payer_info_name?.toLowerCase() === 'mas'
  );

  if (hasKinetik) {
    radioData.push({
      text: 'For Kinetik',
      description: 'Data Kinetik requests to resolve payment issues',
      value: DownloadCsvDetails.KINETIK,
    });
  }

  if (hasMTM) {
    radioData.push({
      text: 'For MTM',
      description: 'This provides reports to upload to MTM',
      value: DownloadCsvDetails.MTM,
    });
  }

  if (hasMAS) {
    radioData.push({
      text: 'For MAS',
      description: 'This provides reports to upload to MAS',
      value: DownloadCsvDetails.MAS,
    });
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="max-w-[400px]">
        <h1 className="pb-2 text-2xl">Select an output format</h1>
        {radioData.map((data) => (
          <div
            className={`rounded-lg border ${
              data.value === downloadCsvDetail
                ? 'border-mint bg-mint'
                : 'border-gray-300'
            }  my-2 cursor-pointer bg-opacity-20 py-4 px-8`}
            onClick={() => setCsvDetail(data.value)}
            key={data.value}
          >
            <div className="flex">
              <div>
                <div className="mr-6 rounded-full border-4 border-mint border-opacity-30 bg-white p-1">
                  <Square3Stack3DIcon className="h-8 w-8 text-mint" />
                </div>
              </div>

              <div>
                <div className="text-lg">{data.text}</div>
                <div className="text-lg text-gray-600">{data.description}</div>
              </div>

              <div>
                <input
                  className="rounded-full border-opacity-30 p-1.5 text-mint ring-0 ring-mint ring-offset-0 checked:ring-0 checked:ring-offset-0 focus:ring-0 focus:ring-offset-0"
                  type="checkbox"
                  checked={data.value === downloadCsvDetail}
                  onChange={() => setCsvDetail(data.value)}
                />
              </div>
            </div>
          </div>
        ))}
        <div className="mt-4 grid grid-cols-2 gap-4">
          <Button onClick={() => dispatch(setShowDownloadModal(false))}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              dispatch(setShowDownloadModal(false));
              dispatch(setShowDownloadDetailsModal(true));
            }}
            variant="primary"
          >
            Next
          </Button>
        </div>
      </div>
    </Modal>
  );
}
