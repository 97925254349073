import { ClockIcon } from '@heroicons/react/24/outline';

import { PaginatedPassengerAddressListRead } from 'src/common/external/bambi-api/bambiApi';
import { SelectOption } from 'src/common/primitives/Select';

interface getLocationOptionAdornmentProps {
  option: SelectOption;
  recentAddressResponse: PaginatedPassengerAddressListRead | undefined;
}

export default function getLocationOptionAdornment({
  option,
  recentAddressResponse,
}: getLocationOptionAdornmentProps) {
  const recentAddress = recentAddressResponse?.results.find(
    (result) => result.address.location?.place_id === option.value
  );

  if (recentAddress) {
    return (
      <span>
        <ClockIcon data-testid="clock-icon" className="h-4 w-4" />{' '}
      </span>
    );
  }

  return undefined;
}
