import { useCallback, useEffect, useState } from 'react';

import { SubmitHandler } from 'react-hook-form';

import { PayerOrganizationRead } from 'src/common/external/bambi-api/bambiApi';
import { useLazyGetTripsExportAsCsvQuery } from 'src/common/external/bambi-api/emptyApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { Button } from 'src/common/primitives/Button';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { usePayerSelectData } from '../../../usePayerSelectData';
import { DetailsFormFields } from '../DetailsFormFields';
import { DetailsForm, useDetailsForm } from '../useDetailsForm';

interface KinetikDetailsExportProps {
  onClose: () => void;
}

export function KinetikDetailsExport({ onClose }: KinetikDetailsExportProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const [trigger] = useLazyGetTripsExportAsCsvQuery();
  const { control, handleSubmit, setValue } = useDetailsForm();

  const { payerOptions, payerData } = usePayerSelectData();

  useEffect(() => {
    const kinetikPayers =
      payerData?.results.filter((p) => p.has_kinetik_account) ?? [];

    const kinetikPayersIdMap = kinetikPayers.reduce((acc, p) => {
      return {
        ...acc,
        [p.id]: p,
      };
    }, {} as Record<string, PayerOrganizationRead>);

    const autoSelectOptions = payerOptions.filter(
      // Check is incorrectly matching any string to any string instead of the specific key
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (option) => !!kinetikPayersIdMap[option.value]
    );

    setValue('selectedPayers', autoSelectOptions);
  }, [payerData, payerOptions, setValue]);

  const handleDownloadTrips = useCallback<SubmitHandler<DetailsForm>>(
    async (fields) => {
      try {
        setError(undefined);
        setLoading(true);

        // Trigger will cause a blob download, see emptyApi
        await trigger({
          rangeStart: fields.startDate?.startOf('day').toISO() ?? '',
          rangeEnd: fields.endDate?.endOf('day').toISO() ?? '',
          payers:
            fields.selectedPayers?.map((payer) => payer.value).join(',') ?? '',
          passengers:
            fields.selectedPassengers
              ?.map((passenger) => passenger.value)
              .join(',') ?? '',
          externalTripIds:
            fields.externalTripIdOptions
              ?.map((option) => option.value)
              .join(',') ?? '',
          tripIds:
            fields.bambiTripIdOptions
              ?.map((option) => option.value)
              .join(',') ?? '',
          outputSet: 'kinetik',
          format: 'csv',
        }).unwrap();
        onClose();
      } catch (e) {
        const errMsg = `There was an error downloading the data. ${formatServerError(
          e
        )}`;
        setError(errMsg);
        setLoading(false);
      }
    },
    [onClose, trigger]
  );

  return (
    <div>
      <h1 className="text-2xl">Kinetik details download</h1>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      <DetailsFormFields
        control={control}
        includeDateRange
        includePayers
        includePassengers
        includeExternalTripIds
        includeBambiTripIds
      />
      <div className="flex flex-row justify-between gap-4 pt-6">
        <div>
          <Button disabled={loading} onClick={onClose}>
            Cancel
          </Button>
        </div>
        <div className="flex flex-row gap-2">
          <Button
            dataTestId="download-csv"
            disabled={loading}
            loading={loading}
            variant="primary"
            onClick={handleSubmit(handleDownloadTrips)}
          >
            {loading ? 'Exporting' : 'Export as CSV'}
          </Button>
        </div>
      </div>
    </div>
  );
}
