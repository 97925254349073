/**
 *
 * @param VINCandidate
 * @returns true if the VIN is valid, otherwise a string describing the error
 *
 * Jacked from https://github.com/thephilip/vindec-validator/blob/master/index.js
 * Which is inspired by https://vin.dataonesoftware.com/vin_basics_blog/bid/112040/use-vin-validation-to-improve-inventory-quality
 * Could eventually enhance with an api that could populate make, model, etc in the form
 * Author of above lib provides an example of hitting https://vpic.nhtsa.dot.gov/api/
 * www.npmjs.com/package/vindec
 */
export const isValidVin = function isValidVin(
  VINCandidate: string
): boolean | string {
  const toValidate = VINCandidate.toUpperCase();

  if (toValidate.length !== 17) {
    return `VIN must be 17 characters long. Currently ${toValidate.length}`;
  }

  if (!/^[A-HJ-NPR-Z0-9]{8}[0-9X][A-HJ-NPR-Z0-9]{8}$/.test(toValidate)) {
    return 'VINs must be alphanumeric except for the letters I, O, and Q.';
  }

  // All 1s, etc will pass the checksum so we check explicitly
  if (isAllSameChar(toValidate)) {
    return 'Invalid VIN (All same characters)';
  }

  try {
    if (isGoodVINChecksum(toValidate)) {
      return true;
    }
  } catch (e) {
    return e instanceof Error ? e.message : 'Invalid VIN';
  }

  return 'Invalid VIN';
};

function isAllSameChar(s: string): boolean {
  return s.split('').every((c) => c === s.charAt(0));
}

/**
 *
 * @param alphaNumericVINCandidate A 17 character alphanumeric string
 * @returns boolean true if the VIN passes the checksum
 * @throws Error if the 9th character of the VIN is not a number or the letter X
 * @throws Error if the VIN contains an unhandled character
 *
 * The 9th character of a VIN is the "check" character. It is a number or the letter X (Meaning the check value is 10).
 *
 * The products of the transliterated characters and their respective index weights are summed and the remainder of that sum divided by 11 should equal the check value.
 *
 * The transliterated characters and their respective index weights are obtained from maps based on https://vin.dataonesoftware.com/vin_basics_blog/bid/112040/use-vin-validation-to-improve-inventory-quality.
 *
 */
function isGoodVINChecksum(alphaNumericVINCandidate: string): boolean {
  const charAtIndex8 = alphaNumericVINCandidate.charAt(8);
  let VINCheckValue = parseInt(charAtIndex8);
  if (Number.isNaN(VINCheckValue)) {
    if (charAtIndex8 === 'X') {
      VINCheckValue = 10;
    } else {
      throw new Error(`Invalid 9th character of VIN: ${charAtIndex8}`);
    }
  }

  const inputVINSum = alphaNumericVINCandidate
    .toUpperCase()
    .split('')
    .map((character, index) => {
      const transliteratedValue = charTransliterationMap[character];
      if (typeof transliteratedValue !== 'number') {
        throw new Error(`Unhandled character in VIN: ${character}`);
      }
      return transliteratedValue * charIndexWeightValues[index];
    })
    .reduce((acc, val) => {
      return acc + val;
    });

  const remainder = inputVINSum % 11;

  return VINCheckValue === remainder;
}

const charTransliterationMap: {
  [key: string]: number;
} = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
  F: 6,
  G: 7,
  H: 8,
  J: 1,
  K: 2,
  L: 3,
  M: 4,
  N: 5,
  P: 7,
  R: 9,
  S: 2,
  T: 3,
  U: 4,
  V: 5,
  W: 6,
  X: 7,
  Y: 8,
  Z: 9,
};

const charIndexWeightValues = [
  8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2,
];
