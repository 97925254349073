import { useCallback, useEffect, useMemo, useState } from 'react';

import { XCircleIcon } from '@heroicons/react/24/outline';
import {
  functionalUpdate,
  PaginationState,
  Updater,
} from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DataGrid } from 'src/common/DataGrid';
import {
  TripRead,
  TripsListApiArg,
  useTripImportRetrieveQuery,
  useTripsListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { DEFAULT_PAGINATION_PAGE_SIZE } from 'src/common/external/bambi-api/constants';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import { show } from 'src/common/primitives/Toast/toast.slice';
import InvoiceController from 'src/features/billing/invoices/InvoiceController';
import { PriceSummaryModal } from 'src/features/pricing/PriceSummaryModal/PriceSummaryModal';
import { useShouldShowRideOrderingPortalFeatures } from 'src/features/rideOrderingPortal/useShouldShowRideOrderingPortalFeatures';
import { DownloadDetailsModal } from 'src/features/trip/management/download/DownloadDetailsModal';
import { DownloadModal } from 'src/features/trip/management/download/DownloadModal';
import { UploadDetailsModal } from 'src/features/trip/management/UploadDetailsModal/UploadDetailsModal';
import { UploadModal } from 'src/features/trip/management/UploadModal';

import {
  InvoicedTripsValue,
  setCsvUploadError,
  setCsvUploadSuccessMessage,
  setSelectedTripRows,
} from '../trip.slice';
import { BulkCancelTripModal } from './cancel/BulkCancelTripModal/BulkCancelTripModal';
import CancelSuggestionsModal from './cancel/CancelSuggestionsModal/CancelSuggestionsModal';
import CancelTripModal from './cancel/CancelTripModal/CancelTripModal';
import { columns, rideOrderingPortalColumns } from './columns';
import { TripEmptyState } from './TripEmptyState';
import TripTagsModal from './TripTagModal';

export function TripManagementRouteContent() {
  const dispatch = useAppDispatch();
  const csvUploadSuccessMessage = useAppSelector((state) => {
    return state.trip.csvUploadSuccessMessage;
  });
  const {
    tripSearchTerm,
    selectedDateRange,
    selectedPayers,
    selectedTags,
    selectedStatuses,
    selectedDrivers,
    csvUploadError,
    uploadJobId,
    selectedTripRows,
    filterVehicles,
    filterPrice,
  } = useAppSelector((state) => state.trip);

  const filterInvoicedTrips = useAppSelector(
    (state) => state.trip.filterInvoicedTrips
  );

  // Transpose the selected trip into a boolean for DataGrid
  const selectedTripRowsAsBoolean = useMemo(() => {
    const result: Record<string, boolean> = {};
    Object.keys(selectedTripRows).forEach((id) => {
      result[id] = !!selectedTripRows[id];
    });
    return result;
  }, [selectedTripRows]);

  const shouldShowRideOrderingPortalFeatures =
    useShouldShowRideOrderingPortalFeatures();

  useTripImportRetrieveQuery(
    {
      id: uploadJobId || '',
    },
    {
      skip: !uploadJobId,
      pollingInterval: 2000,
    }
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGINATION_PAGE_SIZE,
  });

  const hasInvoiceValue = (() => {
    switch (filterInvoicedTrips) {
      case InvoicedTripsValue.ALL_TRIPS:
        return undefined;
      case InvoicedTripsValue.INVOICED_TRIPS:
        return true;
      case InvoicedTripsValue.NOT_INVOICED_TRIPS:
        return false;
    }
  })();

  const {
    data: trips,
    refetch,
    isFetching: isLoading,
  } = useTripsListQuery({
    page: pageIndex + 1,
    search: tripSearchTerm,
    rangeStart: selectedDateRange?.from || '',
    rangeEnd: selectedDateRange?.to || '',
    payerIn: selectedPayers,
    statusIn: selectedStatuses,
    driverIn: selectedDrivers,
    tags: selectedTags.join(',') || '',
    hasInvoice: hasInvoiceValue,
    vehicles: filterVehicles.join(','),
    ordering: useSearchParams()[0].get(
      'ordering'
    ) as TripsListApiArg['ordering'],
    priceMax: filterPrice?.max,
    priceMin: filterPrice?.min,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const handlePaginationChange = (
    paginationUpdater: Updater<PaginationState>
  ) => {
    setPagination((old) => functionalUpdate(paginationUpdater, old));
    refetch();
  };

  const setRowSelection = useCallback(
    (setter: (update: Record<string, boolean>) => Record<string, boolean>) => {
      const next: Record<string, TripRead> = {};

      const result = setter(selectedTripRowsAsBoolean);
      Object.keys(result).forEach((id) => {
        const value = result[id];
        const trip = trips?.results.find((t) => t.id === id) ?? null;

        if (value && trip) {
          next[id] = trip;
        }
      });

      dispatch(setSelectedTripRows(next));
    },
    [dispatch, selectedTripRowsAsBoolean, trips?.results]
  );

  const clearCsvUploadError = () => {
    dispatch(setCsvUploadError(undefined));
  };

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: DEFAULT_PAGINATION_PAGE_SIZE });
    refetch();
  }, [tripSearchTerm, selectedDateRange, selectedPayers, refetch]);

  useEffect(() => {
    if (typeof csvUploadSuccessMessage === 'string') {
      dispatch(
        show({
          title: csvUploadSuccessMessage,
          type: 'success',
        })
      );
      dispatch(setCsvUploadSuccessMessage(null));
    }
  }, [csvUploadSuccessMessage, dispatch]);

  return (
    <>
      {isLoading ? (
        <div className="my-72 flex justify-center">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          {csvUploadError && typeof csvUploadError === 'string' && (
            <FormErrorMessage>
              <div className="flex items-center justify-between">
                <div data-testid="csv-upload-error-message">
                  {csvUploadError}
                </div>
                <div>
                  <XCircleIcon
                    onClick={() => clearCsvUploadError()}
                    className="h-6 w-6 cursor-pointer"
                  />
                </div>
              </div>
            </FormErrorMessage>
          )}

          {csvUploadError &&
            typeof csvUploadError === 'object' &&
            csvUploadError.details && (
              <FormErrorMessage>
                <div className="flex items-center justify-between">
                  <div className="flex flex-col">
                    <div className="font-bold">{csvUploadError.details}</div>
                    {csvUploadError.errors.map((error) => (
                      <div key={error.trip}>
                        <h4 className="font-medium">
                          Errors for trip:{error.trip}
                        </h4>
                        <ul className="list-disc pl-8">
                          {error.errors.map((error) => (
                            <li key={error}>{error}</li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                  <div>
                    <XCircleIcon
                      onClick={() => clearCsvUploadError()}
                      className="h-6 w-6 cursor-pointer"
                    />
                  </div>
                </div>
              </FormErrorMessage>
            )}
          <DataGrid
            tableId="trip-management"
            dataTestId="trip-management"
            columns={
              shouldShowRideOrderingPortalFeatures
                ? rideOrderingPortalColumns
                : columns
            }
            data={trips?.results || []}
            pagination={pagination}
            totalCount={trips?.count || 0}
            handlePaginationChange={handlePaginationChange}
            rowSelection={selectedTripRowsAsBoolean}
            setRowSelection={
              setRowSelection as React.Dispatch<
                React.SetStateAction<Record<string | number | symbol, boolean>>
              >
            }
            getRowId={(row) => row.id}
            emptyState={<TripEmptyState />}
            rowClassName={(row) =>
              row.original.is_will_call && !row.original.is_will_call_activated
                ? 'bg-purple-100'
                : ''
            }
          />
        </>
      )}
      <DownloadModal />
      <DownloadDetailsModal />
      <UploadModal />
      <UploadDetailsModal />
      <TripTagsModal />
      <InvoiceController />
      <BulkCancelTripModal />
      <CancelTripModal />
      <CancelSuggestionsModal />
      <PriceSummaryModal />
    </>
  );
}
