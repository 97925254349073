import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { enhancedBambiApi } from 'src/common/external/bambi-api/enhancedBambiApi';

import { rootReducer } from './rootReducer';

export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: { warnAfter: 150 }, // default is 32ms
        serializableCheck: { warnAfter: 150 }, // default is 32ms
      }).concat(enhancedBambiApi.middleware),
    preloadedState,
  });
}

// TODO: probably can separate this stuff out, but minimizing scope of change
// by creating the store and keeping it exported below
const store = setupStore();

// From react-toolkit
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> =
  useSelector<RootState>;
export default store;
