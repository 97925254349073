import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { useCallback, useRef } from 'react';

import {
  Eventcalendar,
  luxonTimezone,
  MbscCalendarEvent,
  MbscCalendarEventData,
  MbscEventcalendarView,
  MbscResource,
} from '@mobiscroll/react';
import { IValidateProps } from '@mobiscroll/react/dist/src/core/util/datetime';
import * as luxon from 'luxon';
import { useSelector } from 'react-redux';

import { RootState, useAppSelector } from 'src/app/store';
import { useAuth } from 'src/features/auth/useAuth';

import { AssignmentEmptyState } from './AssignmentEmptyState';
import { DispatchCalendarAssignment } from './DispatchCalendarAssignment';
import { DispatchCalendarHeader } from './DispatchCalendarHeader';
import { DispatchCalendarEvent } from './DispatchCalenderEvent';
import { getMobiscrollSelectedDate } from './getMobiscrollSelectedDate';
import useDynamicCss, { css } from './useDynamicCss';

luxonTimezone.luxon = luxon;
// Important -- prevents "Invalid unit zone" error
// Mobiscroll needs to determine the version I believe
// due to using a custom createObject implementation
// luxon changed the signature of createObject in v2
luxonTimezone.version = 3;

// TODO: Move into dispatcher state as CADSettings - Note hack below
const view: MbscEventcalendarView = {
  schedule: {
    type: 'day',
    allDay: false,
    timeCellStep: 30,
    timeLabelStep: 60,
  },
};

const selectSelectedDate = (state: RootState) => state.dispatcher.selectedDate;

export function DispatchCalendar({
  resources = [],
  events = [],
  invalids = [],
}: {
  resources: MbscResource[];
  events: MbscCalendarEvent[];
  invalids: IValidateProps[];
}) {
  const calendarRef = useRef<any>();
  useDynamicCss(calendarRef);
  const selectedDate = useSelector(selectSelectedDate);
  const previewEvents = useAppSelector(
    (state) => state.dispatcher.previewEvents
  );
  const { currentOrganizationHeadQuarters } = useAuth();

  // selecting the first trip in event is good enough
  // to navigate to the trip in the calendar
  const selectedTripEvent =
    previewEvents && previewEvents.length > 0
      ? events.find((event) => event.id === previewEvents[0].trip.id)
      : undefined;

  if (selectedTripEvent && calendarRef.current?.navigateToEvent) {
    calendarRef.current?.navigateToEvent(selectedTripEvent);
  }

  const renderHeader = useCallback(() => <DispatchCalendarHeader />, []);
  const renderResource = useCallback(
    (resource: MbscResource) => (
      <DispatchCalendarAssignment assignment={resource.assignment} />
    ),
    []
  );
  const renderEvent = useCallback(
    (event: MbscCalendarEventData) => <DispatchCalendarEvent event={event} />,
    []
  );

  const isEventCalendarScrollable = useAppSelector(
    (state) => state.dispatcher.isEventCalendarScrollable
  );

  if (!view.schedule) throw new Error('No scheduler view configured');

  // Make Mobiscroll only show 1 day
  // When resources exist this isn't necessary, but if no resources exist
  // it falls back to showing m-f
  // if (resources?.length < 1) {
  //   const viewDay = DateTime.fromISO(selectedDate).day;
  //   view.schedule.startDay = viewDay;
  //   view.schedule.endDay = viewDay;
  // }

  return (
    <>
      <style>{css}</style>
      <div
        className={`dispatch-calendar-root isolate flex h-full max-h-[90vh] max-w-full flex-col gap-2 px-1 pt-3 ${
          isEventCalendarScrollable ? 'scrollable' : ''
        }`}
        data-testid="dispatch-calendar-root"
      >
        {resources.length < 1 && <AssignmentEmptyState />}
        <Eventcalendar
          ref={calendarRef}
          theme="ios"
          themeVariant="light"
          clickToCreate={false}
          dragToCreate={false}
          dragToMove={false}
          dragToResize={false}
          eventDelete={false}
          view={view}
          data={events.length > 0 ? events : [{ id: 1, resource: 1 }]}
          resources={resources.length > 0 ? resources : undefined}
          invalid={invalids}
          renderHeader={renderHeader}
          renderResource={renderResource}
          renderScheduleEvent={renderEvent}
          width="100%"
          selectedDate={getMobiscrollSelectedDate(selectedDate)}
          refDate={getMobiscrollSelectedDate(selectedDate)}
          dataTimezone="utc"
          timezonePlugin={luxonTimezone}
          displayTimezone={currentOrganizationHeadQuarters?.time_zone_name}
          showEventBuffer
        />
      </div>
    </>
  );
}
