import {
  DispatchTripRead,
  RunBambiRunReassignedTripRead,
  RunBambiRunTripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { isActiveTrip } from 'src/common/isActiveTrip';
import colors from 'src/common/theme/colors';
import { TRIP_STATUSES } from 'src/common/tripStatuses';

import {
  RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES,
  RunBambiRunCalendarEventType,
} from '../../common/RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES';
import { isReassignmentPreview } from '../tripReassignmentUtils';

export const TripThemes = {
  OK: {
    bg: colors.trip.scheduled.bg,
    border: colors.trip.scheduled.border,
    text: colors.trip.scheduled.text,
    borderStyle: 'solid',
  },
  OK_ACTIVE: {
    bg: colors.trip.active.bg,
    border: colors.trip.active.border,
    text: colors.trip.active.text,
    borderStyle: 'solid',
  },
  CANCELED: {
    bg: colors.trip.canceled.bg,
    border: colors.trip.canceled.border,
    text: colors.trip.canceled.text,
    borderStyle: 'solid',
  },
  COMPLETED: {
    bg: colors.trip.complete.bg,
    border: colors.trip.complete.border,
    text: colors.trip.complete.text,
    borderStyle: 'solid',
  },
  WILL_CALL: {
    bg: colors.trip.willcall.bg,
    border: colors.trip.willcall.border,
    text: colors.trip.willcall.text,
    borderStyle: 'dotted',
  },
  NEW_ASSIGNMENT: {
    bg: '#0078BB',
    border: '#FFF',
    text: '#FFF',
    borderStyle: 'dotted',
  },
  REASSIGNMENT: {
    bg: '#6941C6',
    border: '#FFF',
    text: '#FFF',
    borderStyle: 'dotted',
  },
  DELAYED: {
    bg: colors.trip.delayed.bg,
    border: colors.trip.delayed.border,
    text: colors.trip.delayed.text,
    borderStyle: 'solid',
  },
};

export function getTripCalendarTheme(
  trip: DispatchTripRead | RunBambiRunTripRead | RunBambiRunReassignedTripRead,
  type?: RunBambiRunCalendarEventType
) {
  if (type === RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES.NEW) {
    return TripThemes.NEW_ASSIGNMENT;
  }

  if (
    type === RUN_BAMBI_RUN_ASSIGNMENT_CATEGORIES.REASSIGNMENT ||
    isReassignmentPreview(trip)
  ) {
    return TripThemes.REASSIGNMENT;
  }

  if (
    trip.is_non_activated_will_call &&
    trip.status !== TRIP_STATUSES.CANCELED
  ) {
    return TripThemes.WILL_CALL;
  }

  if (trip.status === TRIP_STATUSES.COMPLETED) {
    return TripThemes.COMPLETED;
  }

  if (trip.status === TRIP_STATUSES.CANCELED) {
    return TripThemes.CANCELED;
  }

  if ('timing' in trip && trip.timing?.is_on_time === false) {
    return TripThemes.DELAYED;
  }

  if (isActiveTrip(trip.status)) {
    return TripThemes.OK_ACTIVE;
  }

  return TripThemes.OK;
}
