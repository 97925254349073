import { useFormContext } from 'react-hook-form';

import { useAppSelector } from 'src/app/store';
import {
  usePassengersRecentAddressesListQuery,
  usePayersListQuery,
} from 'src/common/external/bambi-api/bambiApi';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormFieldContainer } from 'src/common/FormField/FormFieldContainer';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { LocationTypeahead } from 'src/common/LocationTypeahead';
import { StyledOption } from 'src/common/primitives/ComboBox/StyledOption';
import { SelectOption } from 'src/common/primitives/Select';
import getLocationOptionAdornment from 'src/features/add-trip/TripDetails/getLocationOptionAdornment';
import { useAdditionalResultsQuery } from 'src/features/add-trip/TripDetails/useAdditionalResultsQuery';
import { useShouldShowRideOrderingPortalFeatures } from 'src/features/rideOrderingPortal/useShouldShowRideOrderingPortalFeatures';

export function DropoffAddressContextField() {
  const shouldShowRideOrderingPortalFeatures =
    useShouldShowRideOrderingPortalFeatures();
  const { setValue, watch } = useFormContext();

  const { selectedPassenger } = useAppSelector(
    (state) => state.tripSubscription
  );
  const formPayer = watch('pricing.payer');

  const { data: recentAddressResponse } = usePassengersRecentAddressesListQuery(
    {
      id: selectedPassenger?.id || '',
    },
    {
      skip: !selectedPassenger?.id || shouldShowRideOrderingPortalFeatures,
    }
  );

  const { data: payers } = usePayersListQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const selectedPayer = payers?.results.find((payer) => payer.id === formPayer);

  const additionalResultsQueryFn = useAdditionalResultsQuery(
    recentAddressResponse,
    selectedPayer
  );

  const onLocationChange = (location: SelectOption, field: any) => {
    const recentAddress = recentAddressResponse?.results.find(
      (result) => result.address.location?.place_id === location.value
    );
    if (recentAddress) {
      setValue('dropoff.address_details', recentAddress.address_details);
      setValue('dropoff.driver_notes', recentAddress.address_notes);
    }
    field.onChange({
      value: location.value,
      label: location.label,
    });
  };

  const renderOption = (
    option: SelectOption,
    selected: boolean,
    active: boolean
  ) => {
    const adornment = getLocationOptionAdornment({
      option,
      recentAddressResponse,
    });

    return (
      <StyledOption
        key={option.value}
        option={option}
        selected={selected}
        active={active}
        startAddornment={adornment}
      />
    );
  };

  return (
    <FormFieldController
      name={'dropoff.address'}
      rules={{
        validate(location) {
          if (!location || !location.value) {
            return 'A dropoff location from suggestions is required';
          }
          return true;
        },
      }}
    >
      {({ field, fieldState }) => {
        const inputProps = {
          id: 'dropoff.address',
          ...field,
        };
        return (
          <FormFieldContainer>
            <DefaultFormFieldLayout
              label="Dropoff Address *"
              error={fieldState.error?.message?.toString()}
              inputProps={inputProps}
            >
              <LocationTypeahead
                inputProps={inputProps}
                onChange={(suggestionOption) => {
                  onLocationChange(suggestionOption, field);
                }}
                error={fieldState.error?.message?.toString()}
                additionalResultsQueryFn={additionalResultsQueryFn}
                renderOption={renderOption}
                value={field.value}
              />
            </DefaultFormFieldLayout>
          </FormFieldContainer>
        );
      }}
    </FormFieldController>
  );
}
