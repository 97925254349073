import {
  forwardRef,
  ForwardRefExoticComponent,
  Fragment,
  Ref,
  RefAttributes,
  useEffect,
  useState,
} from 'react';

import { Combobox as HeadlessCombobox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { SelectOption } from 'src/common/primitives/Select';

import { getDisableIconClasses } from '../icon/getDisableIconClasses';
import { baseQueryAsync } from './baseQueryAsync';
import { RenderOptions } from './RenderOptions';

type ComboboxProps = {
  keepValue?: boolean;
  options: SelectOption[];
  onChange?: (value: SelectOption) => void;
  value?: SelectOption;
  error?: string;
  queryFn?: (
    query: string,
    options?: SelectOption[] | undefined
  ) => Promise<SelectOption[]>;
  disabled?: boolean;
  placeholder?: string;
  onQueryChange?: (query: string) => void;
  dataTestId?: string;
  hintText?: string;
  renderOption?: (
    option: SelectOption,
    selected: boolean,
    active: boolean
  ) => React.ReactNode;
  onButtonClick?: () => void;
};

export const Combobox: ForwardRefExoticComponent<
  ComboboxProps & RefAttributes<HTMLInputElement>
> = forwardRef(function _Combobox(
  {
    options,
    onChange,
    queryFn = baseQueryAsync,
    value,
    error,
    disabled,
    placeholder = 'Select an option',
    onQueryChange,
    keepValue,
    dataTestId,
    hintText,
    renderOption,
    onButtonClick,
  },
  forwardedRef?: Ref<HTMLInputElement>
) {
  const [selected, setSelected] = useState(options[0]);
  const [query, setQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  useEffect(() => {
    setFilteredOptions(options);
    setSelected(options[0]);
  }, [options]);

  const baseClass = `relative flex w-full cursor-default items-center justify-between gap-1 rounded-md border bg-white 
    py-2 px-3 text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 
    focus-visible:ring-offset-2  disabled:cursor-not-allowed disabled:text-gray-300 sm:text-sm`;

  const errorClass = error
    ? 'focus:border-red-500 focus-visible:border-red-500 border-red-300 focus-visible:ring-offset-red-300'
    : 'focus:border-indigo-500 focus-visible:border-indigo-500 border-gray-300 focus-visible:ring-offset-blue-300';

  return (
    <>
      <HeadlessCombobox
        // If there's no selected value, make a dumb option to not clear out the user's input
        // Keep value as null so that validations still fail if an actual option isn't chosen
        // This moves the burden of making it clear that an option needs to be chosen to the consumer's error messages, etc
        // If an option has been previously been selected, this will not be cleared out.
        // There's probably a better solution of passing in a value renderer from the consumer point (Namely, the Address typeahead in this case)
        // 2/27/2023 We don't want the Passenger to stay selected after the Add a Trip form closes.
        value={keepValue ? selected : value}
        disabled={disabled}
        onChange={(newSelection) => {
          if (newSelection != null) {
            setSelected(newSelection);
            const labelValuePair = {
              label: newSelection.label,
              value: newSelection.value === null ? '' : newSelection.value,
            };
            typeof onChange === 'function' && onChange(labelValuePair);
          }
        }}
        nullable
      >
        <div className="relative w-full cursor-default">
          <HeadlessCombobox.Input
            className={`${baseClass} ${errorClass}`}
            displayValue={(option: SelectOption | undefined) => {
              return option?.label || '';
            }}
            autoComplete="off"
            data-1p-ignore
            onChange={(event) => {
              setQuery(event.target.value);
              if (onQueryChange) {
                onQueryChange(event.target.value);
                return;
              }

              queryFn(event.target.value, options).then(setFilteredOptions);
            }}
            placeholder={placeholder}
            ref={forwardedRef}
            data-testid={dataTestId}
            data-testvalue={(keepValue ? selected : value)?.value}
          />
          <HeadlessCombobox.Button
            data-testid={`${dataTestId}-button`}
            className="absolute inset-y-0 right-0 flex items-center pr-2"
            onClick={() => {
              queryFn(query, options).then(setFilteredOptions);
              if (onButtonClick) onButtonClick();
            }}
          >
            <ChevronDownIcon
              className={`h-5 w-5 ${getDisableIconClasses(disabled)}`}
              aria-hidden="true"
            />
          </HeadlessCombobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="relative">
            <HeadlessCombobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <RenderOptions
                options={filteredOptions}
                query={query}
                renderOption={renderOption}
              />
            </HeadlessCombobox.Options>
          </div>
        </Transition>
      </HeadlessCombobox>
      {hintText && (
        <div className="flex items-center pt-1">
          <div className="flex items-center justify-between gap-x-1 text-xs text-gray-500">
            {hintText}
          </div>
        </div>
      )}
    </>
  );
});
