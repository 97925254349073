import { useState } from 'react';

import { DateTime } from 'luxon';
import { FormProvider, useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { useLazyGetVehicleInspectionsExportListAsCsvQuery } from 'src/common/external/bambi-api/emptyApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { ContextFormFieldDate } from 'src/common/FormField/v2/context/components';
import { Button } from 'src/common/primitives/Button';
import { Modal } from 'src/common/primitives/Modal';

import { fleetInspectionSlice } from './fleetInspection.slice';

export default function VehicleInspectionsDownloadModal() {
  const dispatch = useAppDispatch();
  const [trigger] = useLazyGetVehicleInspectionsExportListAsCsvQuery();

  const form = useForm({ defaultValues: { startDate: '', endDate: '' } });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const open = useAppSelector(
    (state) => state.fleetInspection.showInspectionDownloadModal
  );

  const handleModalClose = () => {
    form.reset();
    setError(undefined);
    setLoading(false);
    dispatch(fleetInspectionSlice.actions.setInspectionDownloadModal(false));
  };

  const handleDownload = async (values: {
    startDate: string;
    endDate: string;
  }) => {
    setLoading(true);
    const { startDate, endDate } = values;

    try {
      setError(undefined);

      const start = DateTime.fromISO(startDate);
      const end = DateTime.fromISO(endDate);

      await trigger({
        rangeStart: start.startOf('day').toISO(),
        rangeEnd: end.endOf('day').toISO(),
        format: 'csv',
      }).unwrap();

      handleModalClose();
    } catch (error) {
      setError('There was an error downloading the data.');
    }

    setLoading(false);
  };

  return (
    <Modal
      open={open}
      setOpen={(val) =>
        dispatch(fleetInspectionSlice.actions.setInspectionDownloadModal(val))
      }
      contentClassnames="max-w-2xl md:min-w-[400px]"
      data-testid="vehicle-inspections-download-modal"
    >
      <h1 className="mb-4 text-2xl">Download Inspections</h1>

      {error && (
        <div className="pb-4">
          <FormErrorMessage>{error}</FormErrorMessage>
        </div>
      )}
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleDownload)}>
          <div className="flex flex-col gap-4">
            <ContextFormFieldDate
              fieldPath="startDate"
              label="Start Date"
              rules={{
                required: 'Start Date is required.',
                validate: (val) => {
                  if (val && form.watch('endDate')) {
                    return DateTime.fromISO(val) <=
                      DateTime.fromISO(form.watch('endDate'))
                      ? true
                      : 'Start Date must be before End Date.';
                  }
                  return true;
                },
              }}
            />

            <ContextFormFieldDate
              fieldPath="endDate"
              label="End Date"
              rules={{
                required: 'End Date is required.',
                validate: (val) => {
                  if (val && form.watch('startDate')) {
                    return DateTime.fromISO(val) >=
                      DateTime.fromISO(form.watch('startDate'))
                      ? true
                      : 'End Date must be after Start Date.';
                  }
                  return true;
                },
              }}
            />
          </div>

          <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <Button
              dataTestId="cancel-vehicle-inspections-export-btn"
              className="sm:col-span-3"
              disabled={loading}
              onClick={handleModalClose}
            >
              Cancel
            </Button>
            <Button
              className="sm:col-span-3"
              type="submit"
              variant="primary"
              disabled={loading}
              dataTestId="download-vehicle-inspections-btn"
            >
              {loading ? 'Exporting csv...' : 'Export as csv'}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}
