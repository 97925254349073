import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import { intersection } from 'lodash-es';

type PartialTrip = {
  trip_id: string;
};

interface MASReportContextType {
  mapData: Record<string, string>;
  setMapData: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  hasMapData: (newMapData: Record<string, string>) => boolean;
  generateMapStructure: (trips: Array<PartialTrip>) => Record<string, string>;
}

const MASReportContext = createContext<MASReportContextType | undefined>({
  mapData: {},
  setMapData: () => {},
  hasMapData: (_newMapData) => false,
  generateMapStructure: (_trips) => ({}),
});

export const MASReportProvider = ({ children }: { children: ReactNode }) => {
  const [mapData, setMapData] = useState<Record<string, string>>({});
  const hasMapData = useCallback(
    (newMapData: Record<string, string>) => {
      const newMapKeys = Object.keys(newMapData);
      const currentMapKeys = Object.keys(mapData);

      if (!currentMapKeys.length) {
        return false;
      }

      return (
        intersection(newMapKeys, currentMapKeys).length === newMapKeys.length
      );
    },
    [mapData]
  );
  const generateMapStructure = useCallback((trips: Array<PartialTrip>) => {
    return trips.reduce((acc, trip) => {
      return {
        ...acc,
        [trip.trip_id]: '',
      };
    }, {});
  }, []);

  return (
    <MASReportContext.Provider
      value={{ mapData, setMapData, hasMapData, generateMapStructure }}
    >
      {children}
    </MASReportContext.Provider>
  );
};

export const useMASReportContext = (): MASReportContextType => {
  const context = useContext(MASReportContext);
  if (!context) {
    throw new Error(
      'useMASReportContext must be used within a MASReportProvider'
    );
  }
  return context;
};

export default MASReportContext;
