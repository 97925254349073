import { intersection } from 'lodash-es';

import { useFeature } from 'src/app/FeatureToggle';
import { useAppDispatch, useAppSelector } from 'src/app/store';
import { DropdownMenuItem } from 'src/common/DropdownMenu/DropdownMenuItem';
import {
  AssignmentRead,
  DispatchTripRead,
} from 'src/common/external/bambi-api/bambiApi';
import { formatFullName } from 'src/common/util/formatFullName';
import { usePayers } from 'src/features/add-trip/Pricing/usePayers';

import { createEventsFromTrips } from '../../DispatchCalendar/createEventsFromTrips';
import { createEventsFromTripWaitTimes } from '../../DispatchCalendar/createEventsFromTripWaitTimes';
import { useTripMenuActions } from '../../DispatchCalendar/useTripMenuActions';
import {
  onPreviewTripAssignmentEnd,
  onPreviewTripAssignmentStart,
} from '../../dispatcher.slice';
import { getPotentialMultiloadTripIds } from './getPotentialMultiloadTripIds';

type DriverAssignmentMenuActionProps = {
  assignment: AssignmentRead;
  trip: DispatchTripRead;
};

export function DriverAssignmentMenuAction({
  assignment,
  trip,
}: DriverAssignmentMenuActionProps) {
  const dispatch = useAppDispatch();
  const menuActions = useTripMenuActions();
  const isMultiloadEnabled = useFeature('multi-loading');
  const isAssigned = !!trip.assignment_id;
  const assignedTrips = useAppSelector(
    (state) => state.dispatcher.dispatchResponse?.assigned_trips
  );
  const { payers } = usePayers();

  const createPreviewTrip = (originalTrip: DispatchTripRead) => ({
    ...originalTrip,
    assignment_id: assignment.id,
    id: `${originalTrip.id}-preview`,
  });

  const onMouseEnter = () => {
    const tempTrip = createPreviewTrip(trip);
    let previewEvents = createEventsFromTrips([tempTrip]);

    if (trip.inbound_trip) {
      const tempInboundTrip = createPreviewTrip(
        trip.inbound_trip as DispatchTripRead
      );
      previewEvents = createEventsFromTrips([tempTrip, tempInboundTrip]);

      if (trip.wait_time_start !== null && trip.wait_time_end !== null) {
        const waitTimeEvent = createEventsFromTripWaitTimes([tempTrip])[0];
        previewEvents.push({ ...waitTimeEvent, id: `${trip.id}-wait-preview` });
      }
    }

    dispatch(onPreviewTripAssignmentStart(previewEvents));
  };

  return (
    <>
      <DropdownMenuItem
        key={assignment.id}
        data-testid={`driver-assignment-menu-item-${assignment.id}`}
        onClick={() => {
          if (isAssigned) {
            menuActions.reassign(
              trip.id,
              assignment.id,
              trip.status,
              trip.multiload_id
            );
          } else if (isMultiloadEnabled.isEnabled) {
            const potentialMultiloadTripIds = getPotentialMultiloadTripIds(
              trip,
              [...(assignedTrips || [])],
              assignment,
              payers?.results || []
            );
            const tripsAlreadyAssignedToAssignment = assignedTrips?.filter(
              (t) => t.assignment_id === assignment.id
            );
            const hasPotentialMultiloadTripsAssigned =
              Array.isArray(tripsAlreadyAssignedToAssignment) &&
              intersection(
                tripsAlreadyAssignedToAssignment.map((t) => t.id),
                potentialMultiloadTripIds
              ).length > 0;

            if (
              hasPotentialMultiloadTripsAssigned &&
              potentialMultiloadTripIds.length > 0
            ) {
              menuActions.multiloadAssign(
                trip.id,
                assignment.id,
                potentialMultiloadTripIds
              );
            } else {
              menuActions.assign(trip.id, assignment.id, trip.status);
            }
          } else {
            menuActions.assign(trip.id, assignment.id, trip.status);
          }
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => {
          dispatch(onPreviewTripAssignmentEnd());
        }}
      >
        {formatFullName(assignment.driver)}
      </DropdownMenuItem>
    </>
  );
}
