import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { DeleteModal } from 'src/common/DeleteModal';
import { usePricingPricingModelsDestroyMutation } from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { formatRTKQueryError } from 'src/common/util/formatRTKQueryError';

import { useSelectedPricingModel } from '../hooks/useSelectedPricingModel';

export function PricingModelDeleteButton() {
  const navigate = useNavigate();
  const selectedPricingModel = useSelectedPricingModel();
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [deletePricingModel] = usePricingPricingModelsDestroyMutation();
  return (
    <>
      <Button
        onClick={() => {
          setError('');
          setOpen(true);
        }}
        variant="danger"
      >
        Delete
      </Button>
      <DeleteModal
        open={open}
        setOpen={setOpen}
        onCancel={() => {
          setError('');
          setOpen(false);
        }}
        onConfirm={async () => {
          try {
            if (selectedPricingModel?.id) {
              const deleteResponse = await deletePricingModel({
                id: selectedPricingModel.id,
              });
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              if ('error' in deleteResponse && deleteResponse.error) {
                setError(formatRTKQueryError(deleteResponse.error));
                return;
              }
              setOpen(false);
              navigate(`/pricing/pricing-models`);
            }
          } catch (e) {
            setError(
              `Unexpected error deleting pricing model: ${JSON.stringify(e)}`
            );
          }
        }}
        title="Delete this pricing model?"
        description="Are you sure you want to delete this pricing model? This action cannot be undone."
        error={error}
      />
    </>
  );
}
