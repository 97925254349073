import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import {
  PaginatedFeatureFlagListRead,
  useFlagsFeatureValuesWebListQuery,
} from 'src/common/external/bambi-api/bambiApi';

export type WebFeatureFlagQueryReturn = {
  isLoading: boolean;
  data?: PaginatedFeatureFlagListRead;
  error?: FetchBaseQueryError | SerializedError;
};

// This is literally just to sidestep msw in useFeature.test.tsx
// I initially mocked out msw but then had issues waiting for the
// request to finish when rendering the useFeature hook
// I think unit tests are fine in this case although it'd be cool to
// figure out how to test without knowing about. The roi on
// effort seemed low so here we are.
export function useBambiWebFeatureFlagQuery(): WebFeatureFlagQueryReturn {
  const { data, isLoading, error } = useFlagsFeatureValuesWebListQuery({});
  return { data, isLoading, error };
}
