import { forwardRef } from 'react';

import { classNames } from 'src/common/classNames';
import {
  MtmDriverManifestRead,
  MtmDriverManifestTripRead,
} from 'src/common/external/bambi-api/bambiApi';

const universalCellClassName = 'border-b border-r border-black  text-xs';
const universalHeaderClassName =
  'border-b border-r border-t border-black text-center text-xs';

type ManifestTripColumn = {
  accessor: keyof MtmDriverManifestTripRead;
  header: string;
  cellClassName: string;
  headerClassName?: string;
  templateColumn: string;
};
const columns: ManifestTripColumn[] = [
  {
    accessor: 'trip_number',
    header: 'MTM Trip Number',
    cellClassName: 'border-l pl-1 whitespace-nowrap',
    headerClassName: 'border-l',
    templateColumn: '1fr',
  },
  {
    accessor: 'member_name',
    header: "Member's Printed Name",
    cellClassName: 'pl-1 whitespace-nowrap',
    templateColumn: '1fr',
  },
  {
    accessor: 'am_or_pm',
    header: 'AM/PM of Pickup',
    cellClassName: 'text-center',
    templateColumn: '75px',
  },
  {
    accessor: 'scheduled_pickup_time',
    header: 'Scheduled Pickup Time',
    cellClassName: 'text-center',
    templateColumn: '75px',
  },
  {
    accessor: 'pickup_arrival_time',
    header: 'Pickup Arrival',
    cellClassName: 'text-center',
    templateColumn: '75px',
  },
  {
    accessor: 'pickup_departure_time',
    header: 'Pickup Departure',
    cellClassName: 'text-center',
    templateColumn: '75px',
  },
  {
    accessor: 'dropoff_time',
    header: 'Drop Off Time',
    cellClassName: 'text-center',
    templateColumn: '75px',
  },
  {
    accessor: 'member_signature_url',
    header: 'Member Signature',
    cellClassName: 'pl-1',
    templateColumn: '1fr',
  },
];

interface PrintableDailyTripLogProps {
  manifestList: MtmDriverManifestRead[];
}

export const PrintableDailyTripLog = forwardRef<
  HTMLDivElement,
  PrintableDailyTripLogProps
>(({ manifestList }, ref) => {
  return (
    <div
      ref={ref}
      className="w-screen p-2 pt-2 screen:hidden"
      style={{ fontSize: '9.5px' }}
    >
      {manifestList.map((manifest) => (
        <div className="flex flex-col gap-2">
          <div className="flex flex-row self-center">
            <strong className="text-lg underline">
              MTM, INC. DAILY TRIP LOG
            </strong>
          </div>
          <div className="flex flex-col gap-2">
            <div>
              <strong>Transportation Provider:</strong>{' '}
              {manifest.transportation_provider}
            </div>
            <div>
              <strong>Date of Service:</strong> {manifest.date_of_service}
            </div>
            <div>
              <strong>Driver's License Number:</strong>{' '}
              {manifest.driver_license_number}
            </div>
            <div>
              <strong>Vehicle ID Number (VIN, Last five digits):</strong>{' '}
              {manifest.vin_last_5_digits}
            </div>
          </div>
          <div>
            <div
              className="grid"
              style={{
                gridTemplateColumns: columns
                  .map((c) => c.templateColumn)
                  .join(' '),
              }}
            >
              {/* Headers */}
              {columns.map((c) => (
                <div
                  className={classNames(
                    universalHeaderClassName,
                    c.headerClassName,
                    c.cellClassName
                  )}
                >
                  <strong>{c.header}</strong>
                </div>
              ))}
              {/* Data */}
              {manifest.trips.map((manifestTrip) => (
                <>
                  {columns.map((c) => (
                    <div
                      className={classNames(
                        universalCellClassName,
                        c.cellClassName
                      )}
                    >
                      {c.accessor === 'member_signature_url' ? (
                        <img
                          src={manifestTrip.member_signature_url}
                          width={50}
                          height={25}
                          alt={`${manifestTrip.member_name}'s signature for trip`}
                        />
                      ) : (
                        `${manifestTrip[c.accessor]}`
                      )}
                    </div>
                  ))}
                </>
              ))}
            </div>
            <div className="pl-1">
              Each leg of the transport must be documented on separate lines. A
              signature is required for each leg of the transport. AM/PM is
              indicated for the time of the Scheduled Pickup. No shows will be
              indicated with NS in the Drop-Off Time.
            </div>
          </div>
          <div className="flex w-1/2 flex-col gap-2">
            <div>
              <strong>
                I certify that all information contained herein is true and
                accurate, and understand that this statement is made subject to
                the applicable penalities under federal and state law for making
                false declarations.
              </strong>
            </div>
            <div className="flex flex-row gap-2">
              <strong>DRIVER'S SIGNATURE:</strong>
              <img
                src={manifest.driver_signature_url}
                width={75}
                height={37.5}
                alt={`${manifest.driver_name}'s signature`}
              />
            </div>
            <div className="flex flex-row gap-2">
              <strong>DRIVER'S PRINTED NAME:</strong>
              {manifest.driver_name}
            </div>
          </div>
          {/* Page break for printing */}
          <div className="break-after-page" />
        </div>
      ))}
    </div>
  );
});
