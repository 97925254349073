import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';

import { OverrideStatusEnum } from 'src/common/external/bambi-api/bambiApi';
import { useTripRepository } from 'src/features/add-trip/useAddATripOnSubmit/useTripRepository';

// Logic from https://bambihealth.atlassian.net/browse/BAM-612
type PresentStatusEnum = Extends<
  OverrideStatusEnum,
  | 'assigned'
  | 'en route'
  | 'at pickup'
  | 'transporting'
  | 'at dropoff'
  | 'completed'
>;

// Momentm trips are allowed to be rejected after accepted, assigned
type MomentmPresentStatusEnum = Extends<
  OverrideStatusEnum,
  | 'assigned'
  | 'en route'
  | 'at pickup'
  | 'transporting'
  | 'at dropoff'
  | 'completed'
  | 'rejected'
>;

export const ALLOWED_PRESENT_TRIP_STATUSES: PresentStatusEnum[] = [
  'assigned',
  'en route',
  'at pickup',
  'transporting',
  'at dropoff',
  'completed',
];

type PastStatusEnum = Extends<OverrideStatusEnum, 'completed'>;
export const ALLOWED_PAST_TRIP_STATUSES: PastStatusEnum[] = ['completed'];

type FutureStatusEnum = Extends<OverrideStatusEnum, 'rejected'>;

export function useAllowableNextTripStatuses(tripId: string) {
  const [allowableNextTripStatuses, setAllowableNextTripStatuses] = useState<
    | PresentStatusEnum[]
    | PastStatusEnum[]
    | FutureStatusEnum[]
    | MomentmPresentStatusEnum[]
  >([]);

  const tripRepository = useTripRepository();

  useEffect(() => {
    tripRepository.getTrip(tripId).then((trip) => {
      if (!trip) return;
      const startOfDayDateTime = DateTime.local().startOf('day');
      const pickupDateTime = DateTime.fromISO(trip.scheduled_pickup_at);

      if (pickupDateTime < startOfDayDateTime) {
        setAllowableNextTripStatuses(ALLOWED_PAST_TRIP_STATUSES);
        return;
      }

      if (trip.intermediary === 'momentm') {
        const nextAllowedStatuses = getNextAllowedMomentmTripStatus(
          trip.status
        );
        return setAllowableNextTripStatuses(nextAllowedStatuses);
      }

      setAllowableNextTripStatuses(ALLOWED_PRESENT_TRIP_STATUSES);
    });
  }, [tripRepository, tripId]);

  // Extends above protects us from adding non-allowed statuses
  // Not sure why ts didn't complain about this
  return allowableNextTripStatuses as OverrideStatusEnum[];
}

/**
 * Returns a set of statuses that are allowed for the current status for a
 * momentm trip. The goal is to force the user to cycle through the statuses to
 * ensure that the trip has a pickup and dropoff event.
 */
export function getNextAllowedMomentmTripStatus(
  currentStatus: OverrideStatusEnum
): MomentmPresentStatusEnum[] {
  if (['requested', 'pending', 'assigned'].includes(currentStatus)) {
    return ['en route', 'at pickup', 'rejected'];
  }

  if (currentStatus === 'en route') {
    return ['at pickup', 'assigned'];
  }

  if (['at pickup', 'transporting'].includes(currentStatus)) {
    return ['at dropoff', 'assigned'];
  }

  if ('at dropoff' === currentStatus) {
    return ['completed', 'assigned'];
  }

  // We can't uncancel, or unreject through momentm
  if (['canceled', 'rejected'].includes(currentStatus)) {
    return [];
  }

  return ['assigned'];
}
