import { useCallback } from 'react';

import { DateTime } from 'luxon';
import { SubmitHandler, useForm } from 'react-hook-form';

import { SelectOption } from 'src/common/primitives/Select';

export function toDateTime(
  value: string | DateTime | undefined
): DateTime | undefined {
  if (value === undefined) {
    return value;
  }
  return DateTime.isDateTime(value) ? value : DateTime.fromISO(value);
}

export type DetailsForm = {
  startDate?: DateTime;
  endDate?: DateTime;
  externalTripIdOptions?: SelectOption[];
  bambiTripIdOptions?: SelectOption[];
  selectedPayers?: SelectOption[];
  selectedPassengers?: SelectOption[];
};

export function useDetailsForm(defaults?: DetailsForm) {
  const { handleSubmit, ...form } = useForm<DetailsForm>({
    defaultValues: {
      startDate: undefined,
      endDate: undefined,
      externalTripIdOptions: [],
      bambiTripIdOptions: [],
      selectedPayers: [],
      selectedPassengers: [],
      ...defaults,
    },
  });

  const handleSubmitOnValidTransformer = useCallback<
    SubmitHandler<DetailsForm>
  >((fields) => {
    return {
      ...fields,
      startDate: toDateTime(fields.startDate),
      endDate: toDateTime(fields.endDate),
    };
  }, []);

  const transformedHandleSubmit = useCallback(
    (
      onValid: (data: DetailsForm) => void,
      onInvalid?: (errors: any) => void
    ) => {
      return handleSubmit(
        (data) => onValid(handleSubmitOnValidTransformer(data)),
        onInvalid
      );
    },
    [handleSubmit, handleSubmitOnValidTransformer]
  );

  return {
    ...form,
    handleSubmit: transformedHandleSubmit,
  };
}
