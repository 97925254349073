import { useCallback, useState } from 'react';

import { SubmitHandler } from 'react-hook-form';

import { useLazyGetTripsExportAsCsvQuery } from 'src/common/external/bambi-api/emptyApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { Button } from 'src/common/primitives/Button';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { usePayerSelectData } from '../../../usePayerSelectData';
import { DetailsFormFields } from '../DetailsFormFields';
import { DetailsForm, useDetailsForm } from '../useDetailsForm';

interface PayerDetailsExportProps {
  onClose: () => void;
}

export function PayerDetailsExport({ onClose }: PayerDetailsExportProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const { control, handleSubmit } = useDetailsForm();
  const [trigger] = useLazyGetTripsExportAsCsvQuery();

  const { payerData, slugifyPayerName } = usePayerSelectData();

  const handleDownloadTrips = useCallback<SubmitHandler<DetailsForm>>(
    async (fields) => {
      try {
        setError(undefined);
        setLoading(true);

        const rawPayer = payerData?.results.find(
          (payer) => payer.id === fields.selectedPayers?.[0]?.value
        );

        const payerName =
          rawPayer?.payer_info_name || fields.selectedPayers?.[0]?.label;

        if (!payerName) {
          setError('Please select a payer');
          setLoading(false);
          return;
        }

        const outputSet = slugifyPayerName(payerName);

        // Trigger will cause a blob download, see emptyApi
        await trigger({
          rangeStart: fields.startDate?.startOf('day').toISO() ?? '',
          rangeEnd: fields.endDate?.endOf('day').toISO() ?? '',
          outputSet,
          format: 'csv',

          payers:
            fields.selectedPayers?.map((payer) => payer.value).join(',') ?? '',
          externalTripIds:
            fields.externalTripIdOptions
              ?.map((option) => option.value)
              .join(',') ?? '',
          passengers: '',
          tripIds: '',
        }).unwrap();
        onClose();
      } catch (e) {
        const errMsg = `There was an error downloading the data. ${formatServerError(
          e
        )}`;
        setError(errMsg);
        setLoading(false);
      }
    },
    [onClose, payerData?.results, slugifyPayerName, trigger]
  );

  return (
    <div>
      <h1 className="text-2xl">Payer Portal details download</h1>
      <p className=" text-gray-600">
        Downloaded fields are determined by payer.
      </p>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      <DetailsFormFields
        control={control}
        includeDateRange
        includeSinglePayer
        includeExternalTripIds
      />
      <div className="flex flex-row justify-between gap-4 pt-6">
        <div>
          <Button disabled={loading} onClick={onClose}>
            Cancel
          </Button>
        </div>
        <div className="flex flex-row gap-2">
          <Button
            dataTestId="download-csv"
            disabled={loading}
            loading={loading}
            variant="primary"
            onClick={handleSubmit(handleDownloadTrips)}
          >
            {loading ? 'Exporting' : 'Export as CSV'}
          </Button>
        </div>
      </div>
    </div>
  );
}
