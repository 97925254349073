import { useAppDispatch, useAppSelector } from 'src/app/store';
import { BambiMapView } from 'src/common/BambiMapView/BambiMapView';

import { selectTripLocations } from './selectors/selectTripLocations';
import { TripEventMarkers } from './TripEventMarkers';
import { TripSignatureMarkers } from './TripSignatureMarkers';
import { tripSummarySlice } from './tripSummary.slice';

export function TripSummaryMapView() {
  const dispatch = useAppDispatch();
  const tripLocations = useAppSelector(selectTripLocations);
  return (
    <BambiMapView
      mapViewProps={{
        zoom: 11,
        // This is a hack to force useMapRoute to re-render by swapping out data
        // in the store whenever these events happen
        // Without this, sometimes the style data doesn't load before our trip data
        // and unfortunately, the tree isn't aware of the style data loading
        // so useMapRoute doesn't re-render and plot the route
        // Someone please save me from myself at this point

        // A few things I've tried:
        // 1. In the useEffect in useMapRoute, check both map.loaded() and map.isStyleLoaded()
        // 2. Separate out the logic to render the route from useEffect and call it from onLoad and onStyleData here
        // 3. Adding some state to the tripSummary slice like isStyleLoaded and isMapLoaded and updating them here
        // then making them dependencies in useMapRoute or above.
        // # 3 is the current "solution"

        // Next step is to rework our implementation of route rendering to be more react-y
        // like https://github.com/aws-samples/amazon-location-samples/tree/main/amplify-ui-geo-explore/src/components/routes

        onStyleData: (_map) => {
          dispatch(tripSummarySlice.actions.onMapStyleLoaded());
        },
        onLoad: (_map) => {
          dispatch(tripSummarySlice.actions.loadTripLocations(tripLocations));
        },
      }}
    >
      <TripEventMarkers />
      <TripSignatureMarkers />
    </BambiMapView>
  );
}
