import { ExternalLinkIcon } from '@radix-ui/react-icons';

import { useValidateLocalTime } from 'src/common/util/useValidateLocalTime';

export function TimeDriftBanner() {
  const isValidLocalTime = useValidateLocalTime();

  if (isValidLocalTime) {
    return null;
  }

  return (
    <div
      data-testid="timedrift-banner"
      className="bg-yellow-600 p-2 text-center text-base font-bold text-white"
    >
      <div className="flex flex-row justify-center gap-2">
        <span>
          Warning: Your computer clock is out of sync, some features may not
          work properly.
        </span>
        <div className="flex flex-row items-center">
          <a
            className="underline underline-offset-2"
            target="_blank"
            rel="noreferrer"
            href="https://winbuzzer.com/2022/04/19/how-to-sync-your-clock-time-with-an-internet-time-server-in-windows-11-xcxwbt/"
          >
            Windows Help
          </a>
          <ExternalLinkIcon />
        </div>
        <div className="flex flex-row items-center">
          <a
            className="underline underline-offset-2"
            target="_blank"
            rel="noreferrer"
            href="https://support.apple.com/guide/mac-help/mchlp2996/mac#:~:text=On%20your%20Mac%2C%20choose%20Apple,may%20need%20to%20scroll%20down.)&text=Turn%20on%20%E2%80%9CSet%20time%20and,automatically%20using%20your%20current%20location.%E2%80%9D"
          >
            Mac OS Help
          </a>
          <ExternalLinkIcon />
        </div>
      </div>
    </div>
  );
}
