import { useMemo, useRef, useState } from 'react';

import { throttle } from 'lodash-es';

import { TripEventRead } from 'src/common/external/bambi-api/bambiApi';
import { LoadingIndicator } from 'src/common/primitives/LoadingIndicator';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { MASReportRecord } from '../../management/download/details/mas/types';
import { MASReportMapScreenshotRenderer } from './components/MASReportMapScreenshotRenderer';
import { MASReportRenderer } from './components/MASReportRenderer';
import { MASReportProvider } from './MASReportContext';
import { useFetchBulkTripEvents } from './useFetchBulkTripEvents';

interface MASReportProps {
  trips: MASReportRecord[];
  onDocumentReady: (blob: Blob | null) => void;
}

/**
 * Renders the map screenshot renderer and document renderer within the report
 * provider context. The map screenshot renderer will render a map per trip, and
 * capture a screenshot of it. The report renderer will wait to trigger
 * onDocumentReady once it has map data, and trip data rendered as a PDF.
 */
export function MASReport({ trips, onDocumentReady }: MASReportProps) {
  // Sometimes there is some jitter between renders, this should help ensure
  // that onDocumentReady fires once
  const handleTriggerOnDocumentReady = useRef(
    throttle(onDocumentReady, 500, {
      leading: true,
      trailing: false,
    })
  );

  const [renderedMapCount, setRenderedMapCount] = useState(0);
  const hasRenderedAllMaps = renderedMapCount === trips.length;
  const tripIds = useMemo(() => trips.map((t) => t.trip_id), [trips]);

  const { loading, error, data } = useFetchBulkTripEvents(tripIds);

  const filteredTripEvents = useMemo(() => {
    if (data && !loading && !error) {
      const map: Record<string, TripEventRead[]> = {};
      tripIds.forEach((id) => {
        const events = data[id].filter((e) => {
          return e.latitude && e.longitude;
        });

        map[id] = events;
      });

      return map;
    }

    return {};
  }, [data, error, loading, tripIds]);

  if (error) {
    return <div>Unable to fetch trip events: {formatServerError(error)}</div>;
  }

  return (
    <MASReportProvider>
      <div style={{ position: 'fixed', top: -1000, left: -1000 }}>
        {!loading && data ? (
          <>
            <MASReportMapScreenshotRenderer
              trips={trips}
              tripEvents={filteredTripEvents}
              onMapRendered={() => setRenderedMapCount(renderedMapCount + 1)}
            />
            <MASReportRenderer
              trips={trips}
              tripEvents={data}
              onDocumentReady={handleTriggerOnDocumentReady.current}
            />
          </>
        ) : null}
      </div>
      <div className="flex flex-col items-center">
        <LoadingIndicator />
        {!hasRenderedAllMaps ? (
          <span>
            Rendering map data {renderedMapCount + 1}/{trips.length}
          </span>
        ) : (
          <span>Rendering PDF</span>
        )}
      </div>
    </MASReportProvider>
  );
}
