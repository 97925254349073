// OneSchema has no documentation on their error codes. 3 is the only one we've seen so far.
// I've requested documentation from them, but in the meantime, we'll need to handle this error code.
const oneSchemaErrorCodeToMessageMap: Record<string, string> = {
  '3': 'The import flow failed due to a template key mismatch. Please contact us and let us know which template you are trying to use.',
  default:
    'The import flow failed to launch potentially due to a template key mismatch. Please retry and contact us if it continues.',
};

export function getOneSchemaErrorMessageFromCode(code: string) {
  return (
    oneSchemaErrorCodeToMessageMap[code] ||
    oneSchemaErrorCodeToMessageMap['default']
  );
}
