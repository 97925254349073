import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import { Modal } from 'src/common/primitives/Modal';

import {
  DownloadCsvDetails,
  setShowDownloadDetailsModal,
} from '../../trip.slice';
import { AllDetailsExport } from './details/allDetails/AllDetailsExport';
import { EvertransitDetailsExport } from './details/evertransit/EvertransitDetailsExport';
import { KinetikDetailsExport } from './details/kinetik/KinetikDetailsExport';
import { PrintableMASDetailsExport } from './details/mas/PrintableMASDetailsExport';
import { MTMDetailsExport } from './details/mtm/MTMDetailsExport';
import { PayerDetailsExport } from './details/payer/PayerDetailsExport';

export function DownloadDetailsModal() {
  const dispatch = useDispatch();

  const open = useSelector(
    (state: RootState) => state.trip.showDownloadDetailsModal
  );

  const downloadCsvDetail = useSelector(
    (state: RootState) => state.trip.downloadCsvDetail
  );

  const closeModal = useCallback(() => {
    dispatch(setShowDownloadDetailsModal(false));
  }, [dispatch]);

  const child = (() => {
    switch (downloadCsvDetail) {
      case DownloadCsvDetails.EVERTRANSIT:
        return <EvertransitDetailsExport onClose={closeModal} />;
      case DownloadCsvDetails.KINETIK:
        return <KinetikDetailsExport onClose={closeModal} />;
      case DownloadCsvDetails.PAYER:
        return <PayerDetailsExport onClose={closeModal} />;
      case DownloadCsvDetails.MTM:
        return <MTMDetailsExport onClose={closeModal} />;
      case DownloadCsvDetails.MAS:
        return <PrintableMASDetailsExport onClose={closeModal} />;
      default:
      case DownloadCsvDetails.ALL:
        return <AllDetailsExport onClose={closeModal} />;
    }
  })();

  return (
    <Modal
      open={open}
      setOpen={closeModal}
      contentClassnames="max-w-lg flex flex-col gap-2"
    >
      {child}
    </Modal>
  );
}
