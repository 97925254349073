import { ConfirmModal } from 'src/common/modals/ConfirmModal';

interface ConfirmPayerDeactivationModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export default function ConfirmPayerDeactivationModal({
  open,
  onCancel,
  onConfirm,
}: ConfirmPayerDeactivationModalProps) {
  return (
    <ConfirmModal
      open={open}
      setOpen={() => {
        // do nothing
        // make user click confirm or cancel
      }}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title="Confirm Disable Payer"
      description={
        'Are you sure you want to disable this payer? ' +
        'All unassigned trips for this payer will no ' +
        'longer be visible on the dispatch page, ' +
        'including trips from subscriptions.'
      }
      confirmText="Confirm"
      cancelText="Cancel"
      mode="neutral"
    />
  );
}
