import { createApi } from '@reduxjs/toolkit/query/react';
import { DateTime } from 'luxon';
import { saveResponseUsingHiddenElement } from 'src/common/util/saveResponseUsingHiddenElement';
import { TripEventRead } from './bambiApi';
import { bambiBaseQuery } from './bambiBaseQuery';

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  baseQuery: bambiBaseQuery,
  endpoints: () => ({}),
});

const customTripEndpoints = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    // We do not want to cache files in RTKQuery as we could crash the browser
    // The code gen also does not make it possible to parse the response as a blob
    // For more info: https://github.com/reduxjs/redux-toolkit/issues/1522
    getTripsExportAsCsv: builder.query({
      keepUnusedDataFor: 0,
      queryFn: async (
        {
          rangeStart,
          rangeEnd,
          payers,
          passengers,
          outputSet,
          organizationId,
          format,
          status,
          externalTripIds,
          tripIds,
        }: {
          rangeStart?: string;
          rangeEnd?: string;
          payers?: string;
          passengers?: string;
          outputSet?: string;
          organizationId?: string;
          format?: string;
          status?: string;
          externalTripIds?: string;
          tripIds?: string;
        },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const result = await baseQuery({
          url: `/trips/export/?range_start=${rangeStart || ''}&range_end=${
            rangeEnd || ''
          }&payers=${payers || ''}&passengers=${passengers || ''}&output_set=${
            outputSet || ''
          }${status ? '&status=' + status : ''}${
            externalTripIds ? '&external_trip_id__in=' + externalTripIds : ''
          }${tripIds ? '&id__in=' + tripIds : ''}`,
          method: 'GET',
          headers: {
            'organization-id': organizationId,
          },
          responseHandler: (response) => {
            if (format && format === 'pdf') {
              return response.text();
            }
            return response.blob();
          },
        });

        // @ts-ignore
        const responseHeaders: Headers = result.meta?.response?.headers;
        const fallback = `trip_export_${DateTime.now().toFormat(
          'MMddyyyyHHmmss'
        )}.csv`;
        const fileName = getFilename(responseHeaders, fallback);

        // PDF exports are handled separately
        if (format && format === 'pdf') {
          return {
            data: {
              records: result.data,
              fileName,
            },
          };
        }

        saveResponseUsingHiddenElement(result, fileName);
        return { data: null };
      },
    }),
    getAssignmentExportAsCsv: builder.query({
      keepUnusedDataFor: 0,
      queryFn: async (
        { rangeStart, rangeEnd, outputSet, organizationId },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const result = await baseQuery({
          url: `/assignments/export/?range_start=${
            rangeStart || ''
          }&range_end=${rangeEnd || ''}&output_set=${outputSet || ''}`,
          method: 'GET',
          headers: {
            'organization-id': organizationId,
          },
          responseHandler: (response) => response.blob(),
        });

        if (result.error) {
          return {
            error: {
              status:
                typeof result.error.status === 'number'
                  ? result.error.status
                  : 500,
              statusText: 'Error downloading file',
              data: null,
            },
          };
        }

        // @ts-ignore
        const responseHeaders: Headers = result.meta?.response?.headers;
        const fallback = `assignment_export_${DateTime.now().toFormat(
          'MMddyyyyHHmmss'
        )}.csv`;
        const fileName = getFilename(responseHeaders, fallback);

        saveResponseUsingHiddenElement(result, fileName);
        return { data: 'Download started' };
      },
    }),
    getInvoicesExportListAsCsv: builder.query({
      keepUnusedDataFor: 0,
      queryFn: async (
        { rangeStart, rangeEnd, tripPayerIn, invoice, organizationId },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const result = await baseQuery({
          url: `/invoices/export/?range_start=${rangeStart || ''}&range_end=${
            rangeEnd || ''
          }&trip__payer__in=${tripPayerIn || ''}&invoice=${invoice || ''}`,
          method: 'GET',
          headers: {
            'organization-id': organizationId,
          },
          responseHandler: (response) => response.blob(),
        });

        // @ts-ignore
        const responseHeaders: Headers = result.meta?.response?.headers;
        const fallback = `invoice_list_export_${DateTime.now().toFormat(
          'MMddyyyyHHmmss'
        )}.csv`;
        const fileName = getFilename(responseHeaders, fallback);

        saveResponseUsingHiddenElement(result, fileName);
        return { data: null };
      },
    }),
    getVehicleInspectionsExportListAsCsv: builder.query({
      keepUnusedDataFor: 0,
      queryFn: async (
        { rangeStart, rangeEnd },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        const now = DateTime.now().toFormat('MMddyyyyHHmmss');
        const encodedRangeStart = encodeURIComponent(rangeStart || '');
        const encodedRangeEnd = encodeURIComponent(rangeEnd || '');
        const params = `range_start=${encodedRangeStart}&range_end=${encodedRangeEnd}`;
        const result = await baseQuery({
          url: `/vehicles/inspections/export/?${params}`,
          method: 'GET',
          responseHandler: (response) => response.blob(),
        });

        // @ts-ignore
        const responseHeaders: Headers = result.meta?.response?.headers;
        const fallback = `vehicle_inspections_export_${now}.csv`;
        const fileName = getFilename(responseHeaders, fallback);

        saveResponseUsingHiddenElement(result, fileName);
        return { data: null };
      },
    }),
    getBulkTripEvents: builder.query<TripEventRead[], { trip_ids: string[] }>({
      queryFn: async ({ trip_ids }, _api, _extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `/trips/bulk-trip-events/`,
          method: 'POST',
          body: JSON.stringify({ trip_ids }),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (result.error) {
          return { error: result.error };
        }

        return {
          data: result.data as TripEventRead[], // Type assertion to ensure correct response type
        };
      },
    }),
  }),
});

function getFilename(headers: Headers, fallback: string): string {
  const content = headers?.get('content-disposition');
  if (!content) {
    return fallback;
  }

  const filename = content.match(/filename="(.*)?"/);
  if (!filename || !filename[1]) {
    return fallback;
  }

  return filename[1];
}

export const {
  useLazyGetTripsExportAsCsvQuery,
  useLazyGetAssignmentExportAsCsvQuery,
  useLazyGetInvoicesExportListAsCsvQuery,
  useLazyGetVehicleInspectionsExportListAsCsvQuery,
  useLazyGetBulkTripEventsQuery,
} = customTripEndpoints;
