import { TrashIcon } from '@heroicons/react/24/outline';

import { Button } from 'src/common/primitives/Button';

type DeleteActionProps = {
  className?: string;
  onDelete: () => void;
  label: string;
  disabled?: boolean;
};

export function DeleteAction({
  className,
  onDelete,
  label,
  disabled,
}: DeleteActionProps) {
  return (
    <Button
      className={`${className}`}
      onClick={onDelete}
      variant="danger"
      disabled={disabled}
    >
      <TrashIcon className="mr-2 w-5" /> {label}
    </Button>
  );
}
