import { DateTime } from 'luxon';

import { useWhatTimeIsItRetrieveQuery } from '../external/bambi-api/bambiApi';

// Maximum drift is approx 5 minutes for TLS certs
const MAX_DIFFERENCE_IN_MS = 1000 * 60 * 5;
const TWO_MINUTES_IN_MS = 1000 * 60 * 2;

export function useValidateLocalTime() {
  const { isLoading, isFetching, data, error } = useWhatTimeIsItRetrieveQuery(
    undefined,
    {
      pollingInterval: TWO_MINUTES_IN_MS,
    }
  );

  if (isLoading || isFetching || !data || error) {
    return true;
  }

  // Using browsers date which is local to machine's TZ rather than Org TZ
  const localTime = DateTime.fromJSDate(new Date());
  const serverTime = DateTime.fromISO(data.server_time, { zone: 'UTC' });
  const diff = localTime.toMillis() - serverTime.toMillis();

  return Math.abs(diff) < MAX_DIFFERENCE_IN_MS;
}
