import { VehicleRead } from 'src/common/external/bambi-api/bambiApi';

import { defaultFormValues } from './defaultFormValues';
import { FormValues } from './FormValues';

export function assignDefaultFormValues(
  vehicle: VehicleRead | undefined
): FormValues {
  return {
    ...defaultFormValues,
    ...(vehicle || {}),
    storage_location: vehicle?.storage_location
      ? {
          label: vehicle.storage_location.label,
          value: vehicle.storage_location.place_id,
        }
      : null,
  };
}
