import { forwardRef } from 'react';

import Switch from 'src/common/primitives/Switch';

import { DefaultFormFieldLayout } from './DefaultFormFieldLayout';
import { FormFieldProps } from './FormField';

type FormFieldSwitchProps<T> = Pick<
  FormFieldProps<T>,
  'inputProps' | 'label' | 'helperText' | 'error'
>;

export const FormFieldSwitch = forwardRef(function _FormFieldSwitch<T>(
  { inputProps, label = '', helperText = '', error }: FormFieldSwitchProps<T>,
  ref: React.ForwardedRef<unknown>
) {
  return (
    <DefaultFormFieldLayout
      label={label}
      inputProps={inputProps}
      helperText={helperText}
      error={error}
    >
      <Switch
        data-testid={inputProps.id}
        label={label}
        inputProps={inputProps}
        ref={ref}
      />
    </DefaultFormFieldLayout>
  );
});
