import { PropsWithChildren, useCallback, useRef } from 'react';

import html2canvas from 'html2canvas';

import { SuspendedMapView } from 'src/common/BambiMapView/BambiMapView';
import { useAuth } from 'src/features/auth/useAuth';

interface CaptureMapAsImageProps extends PropsWithChildren {
  width?: number;
  height?: number;
  onCapture: (blobUrl: string) => void;
}
export function CaptureMapAsImage({
  width = 800,
  height = 600,
  onCapture,
  children,
}: CaptureMapAsImageProps) {
  // Grabbing HQ lat/lon from this
  const auth = useAuth();
  const ref = useRef<HTMLDivElement>(null);
  const captureImage = useCallback(async () => {
    if (!ref.current) {
      return;
    }

    try {
      const canvas = await html2canvas(ref.current);
      canvas.toBlob((blob) => {
        if (!blob) {
          throw new Error('Unable to capture image as blob');
        }
        const url = URL.createObjectURL(blob);
        onCapture(url);
      });
    } catch (e) {
      console.error('Unable to generate canvas for ref', ref, e);
      return;
    }
  }, [onCapture]);

  return (
    <div ref={ref} style={{ width, height }}>
      <SuspendedMapView
        latitude={auth.currentOrganizationHeadQuarters?.latitude}
        longitude={auth.currentOrganizationHeadQuarters?.longitude}
        zoom={11}
        onLoad={captureImage}
        preserveDrawingBuffer
        includeNavigationControl={false}
      >
        {children}
      </SuspendedMapView>
    </div>
  );
}
