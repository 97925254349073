import { useCallback } from 'react';

import { TripEventRead } from 'src/common/external/bambi-api/bambiApi';
import { MASReportRecord } from 'src/features/trip/management/download/details/mas/types';
import { TripEventMarker } from 'src/features/trip/summary/TripEventMarker';

import { useMASReportContext } from '../MASReportContext';
import { CaptureMapAsImage } from './CaptureMapAsImage';

interface MASReportMapScreenshotRendererProps {
  trips: MASReportRecord[];
  tripEvents: Record<string, TripEventRead[]>;
  onMapRendered: (tripIndex: number) => void;
}

/**
 * Pairs trips with trip event data, renders the event data on a map. Finally
 * captures the rendered map as a blob, and stores it in context.
 */
export function MASReportMapScreenshotRenderer({
  trips,
  tripEvents,
  onMapRendered,
}: MASReportMapScreenshotRendererProps) {
  const { hasMapData, generateMapStructure, setMapData, mapData } =
    useMASReportContext();
  const newMapData = generateMapStructure(trips);

  const captureMapImage = useCallback(
    (tripId: string, blobUrl: string) => {
      setMapData({
        ...mapData,
        [tripId]: blobUrl,
      });
    },
    [mapData, setMapData]
  );

  if (hasMapData(newMapData)) {
    return null;
  }

  return (
    <>
      {trips.map((trip, index) => {
        const eventData = tripEvents[trip.trip_id] as
          | TripEventRead[]
          | undefined;
        return (
          <CaptureMapAsImage
            onCapture={(blobUrl) => {
              captureMapImage(trip.trip_id, blobUrl);
              onMapRendered(index);
            }}
            key={trip.trip_id}
          >
            {eventData?.map((event) => (
              <TripEventMarker key={event.id} tripEventMarker={event} />
            ))}
          </CaptureMapAsImage>
        );
      })}
    </>
  );
}
