import { stateRegexes } from './stateRegexes';

export const validateDriversLicense = (
  licenseNumber: string,
  state: string
) => {
  const stateRegex = stateRegexes[state];
  const isValid = stateRegex.some((regex) => regex.regex.test(licenseNumber));

  if (!isValid) {
    return false;
  }

  return true;
};
