import { useAppDispatch } from 'src/app/store';
import { DeleteAction } from 'src/common/DeleteAction';
import { useDriversListQuery } from 'src/common/external/bambi-api/bambiApi';
import {
  setShowAttendantDeleteModal,
  setShowAttendantProfileModal,
} from 'src/features/team/team.slice';

export function AttendantDeleteAction({
  membershipId,
}: {
  membershipId: string;
}) {
  const dispatch = useAppDispatch();
  const { isAlsoDriver, isLoading, isFetching } = useDriversListQuery(
    {
      membershipIdIn: [membershipId],
    },
    {
      skip: !membershipId,
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        isAlsoDriver: !!data?.results.length,
        isLoading,
        isFetching,
      }),
    }
  );

  return (
    <DeleteAction
      disabled={isLoading || isFetching || isAlsoDriver}
      onDelete={() => {
        dispatch(setShowAttendantDeleteModal(true));
        dispatch(setShowAttendantProfileModal(false));
      }}
      label={
        isAlsoDriver
          ? 'Unable to delete attendant that is also a driver'
          : 'Delete this attendant'
      }
    />
  );
}
