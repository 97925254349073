import { useFormContext } from 'react-hook-form';

import { FormField } from 'src/common/FormField';
import { DefaultFormFieldLayout } from 'src/common/FormField/DefaultFormFieldLayout';
import { FormFieldController } from 'src/common/FormField/FormFieldController';
import { Button } from 'src/common/primitives/Button';
import { PhoneInput } from 'src/common/primitives/PhoneInput';
import { Select } from 'src/common/primitives/Select';
import { useUserRoles } from 'src/features/auth/useUserRoles';

import { GlobalPayerSelect } from './fields/GlobalPayerSelect';
import PayerActiveToggleField from './PayerActiveToggleField';

export function PayersFormStep1({
  onClose,
  setStep,
}: {
  onClose: () => void;
  setStep: (step: number) => void;
}) {
  const form = useFormContext();
  const { isBambi } = useUserRoles();

  const payerTypeOptions = [
    { label: 'Broker', value: 'broker' },
    { label: 'Insurance', value: 'insurance' },
    { label: 'Private/Invoice', value: 'private' },
  ];

  const onNavigateToStep2 = async () => {
    const isValid = await form.trigger();
    if (isValid) {
      setStep(2);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-3 py-4">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <FormFieldController
              name="display_name"
              rules={{ required: 'Display name is required' }}
            >
              {({ field, fieldState }) => {
                return (
                  <FormField
                    label="Display Name*"
                    error={fieldState.error?.message?.toString()}
                    type="text"
                    inputProps={{
                      id: 'display_name',
                      ...field,
                      'data-testid': 'display_name',
                    }}
                    value={field.value}
                  />
                );
              }}
            </FormFieldController>
          </div>
          <div>
            <FormFieldController name="alternate_name">
              {({ field, fieldState }) => {
                return (
                  <FormField
                    label="Alternative Name"
                    error={fieldState.error?.message?.toString()}
                    type="text"
                    inputProps={{
                      id: 'alternate_name',
                      ...field,
                    }}
                    value={field.value}
                  />
                );
              }}
            </FormFieldController>
          </div>
        </div>

        <h2 className=" text-xl">Basic Information</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <FormFieldController name="type">
              {({ field, fieldState }) => {
                return (
                  <DefaultFormFieldLayout
                    label="Payer Type"
                    error={fieldState.error?.message?.toString()}
                    inputProps={{ id: 'payer_type' }}
                  >
                    <Select
                      value={field.value}
                      options={payerTypeOptions}
                      onChange={(selected) => {
                        field.onChange(selected?.value);
                      }}
                    />
                  </DefaultFormFieldLayout>
                );
              }}
            </FormFieldController>
          </div>
          <div>
            <FormFieldController
              name="website"
              rules={{
                validate: (value) => {
                  if (value) {
                    try {
                      new URL(value);
                      return true;
                    } catch (err) {
                      return 'Invalid URL';
                    }
                  }
                  return true;
                },
              }}
            >
              {({ field, fieldState }) => {
                return (
                  <FormField
                    label="Website"
                    error={fieldState.error?.message?.toString()}
                    type="text"
                    inputProps={{
                      id: 'website',
                      ...field,
                    }}
                    value={field.value}
                  />
                );
              }}
            </FormFieldController>
          </div>
          <div>
            <FormFieldController name={'phone_number'}>
              {({ field }) => {
                return (
                  <div>
                    <DefaultFormFieldLayout
                      label="Phone Number"
                      inputProps={{ id: 'phone_number' }}
                    >
                      <PhoneInput
                        {...field}
                        placeholder="(555) 555-5555"
                        dataTestId="phone"
                      />
                    </DefaultFormFieldLayout>
                  </div>
                );
              }}
            </FormFieldController>
          </div>

          <div>
            <FormFieldController name="email">
              {({ field, fieldState }) => {
                return (
                  <FormField
                    label="Email"
                    error={fieldState.error?.message?.toString()}
                    type="email"
                    inputProps={{
                      id: 'email',
                      ...field,
                    }}
                    value={field.value}
                  />
                );
              }}
            </FormFieldController>
          </div>
          <div>
            <FormFieldController name="ein">
              {({ field, fieldState }) => {
                return (
                  <FormField
                    label="EIN"
                    error={fieldState.error?.message?.toString()}
                    type="text"
                    inputProps={{
                      id: 'ein',
                      ...field,
                    }}
                    value={field.value}
                  />
                );
              }}
            </FormFieldController>
          </div>
          <div>
            <FormFieldController name="external_id">
              {({ field, fieldState }) => {
                return (
                  <FormField
                    label="External Id"
                    error={fieldState.error?.message?.toString()}
                    type="text"
                    inputProps={{
                      id: 'external_id',
                      ...field,
                    }}
                    value={field.value}
                  />
                );
              }}
            </FormFieldController>
          </div>
          <div>
            <PayerActiveToggleField />
          </div>
          {isBambi ? (
            <div>
              <FormFieldController name="payer">
                {({ field, fieldState }) => {
                  return (
                    <GlobalPayerSelect
                      onChange={field.onChange}
                      value={field.value}
                      error={fieldState.error?.message?.toString()}
                    />
                  );
                }}
              </FormFieldController>
            </div>
          ) : null}
        </div>
      </div>
      <div className="mt-8 grid grid-cols-2 gap-4">
        <Button
          dataTestId="step1-cancel"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => onNavigateToStep2()}
          className="w-full flex-grow"
          variant="primary"
          dataTestId="step1-next"
        >
          Next
        </Button>
      </div>
    </>
  );
}
