import { useCallback, useState } from 'react';

import { isError, uniq } from 'lodash-es';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import { BulletList } from 'src/common/BulletList';
import {
  BulkTripValidateResults,
  DispatchTripRead,
  OrderedMultiloadTripLocationDetail,
  useTripsBulkAssignCreateMutation,
  useTripsBulkValidateCreateMutation,
  useTripsMultiloadCreateMutation,
  useTripsMultiloadPartialUpdateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { FormErrorMessage } from 'src/common/FormErrorMessage';
import { Button } from 'src/common/primitives/Button';
import { VerticalSortableDragAndDropList } from 'src/common/SortableDragAndDropList/SortableDragAndDropList';
import formatServerError from 'src/common/util/serverErrorFormatter';

import { dispatcherSlice, execTripMenuAction } from '../dispatcher.slice';
import { TripCard } from '../trips/TripCard/TripCard';
import { MultiloadIcon } from './MultiloadIcon';
import { useMultiloadOrderItems } from './useMultiloadOrderItems';

export type MultiloadPickupDropoffOrderItem = {
  // This will be the id of the trip + type
  id: string;
  trip: DispatchTripRead;
  type: 'pickup' | 'dropoff';
  // Used for initial order
  scheduledAt: string;
  order: number | null;
};

export function MultiloadOrderStep() {
  const dispatch = useAppDispatch();
  const [serverError, setServerError] = useState<string | null>(null);
  const [bulkTripValidateResults, setBulkTripValidateResults] =
    useState<BulkTripValidateResults | null>(null);
  const unassignedTrips = useAppSelector(
    (state) => state.dispatcher.dispatchResponse?.unassigned_trips
  );
  const assignedTrips = useAppSelector(
    (state) => state.dispatcher.dispatchResponse?.assigned_trips
  );
  const [isLoading, setIsLoading] = useState(false);
  const {
    selectedTripIdsToMultiload,
    originatingTripId,
    assignmentIdToMultiload,
  } = useAppSelector((state) => state.dispatcher.multiloadAssignmentFlow);

  const [createTripMultiload] = useTripsMultiloadCreateMutation();
  const [updateTripMultiload] = useTripsMultiloadPartialUpdateMutation();
  const [bulkAssign] = useTripsBulkAssignCreateMutation();
  const [bulkValidate] = useTripsBulkValidateCreateMutation();
  const { orderedMultiLoadItems, setOrderedMultiLoadItems } =
    useMultiloadOrderItems();
  // TODO: Would like to move this out to a hook or something
  const onAssignClick = useCallback(
    ({ shouldValidate = true }: { shouldValidate: boolean }) => {
      if (!(unassignedTrips && assignedTrips)) {
        return null;
      }

      if (shouldValidate && bulkTripValidateResults) {
        setBulkTripValidateResults(null);
      }

      setServerError(null);
      const orderedTripLocationDetailIds: OrderedMultiloadTripLocationDetail[] =
        orderedMultiLoadItems.map((orderItem, index) => {
          const trip = orderItem.trip;
          const trip_location_detail_id =
            orderItem.type === 'pickup'
              ? trip.pickup_trip_location_detail_id
              : trip.dropoff_trip_location_detail_id;
          if (!trip_location_detail_id) {
            throw new Error('location detail not found');
          }

          return {
            trip_location_detail_id,
            order: index + 1,
          };
        });

      setIsLoading(true);
      dispatch(
        execTripMenuAction({
          action: async () => {
            // We probably also need for the assignment and multiload create to be all or nothing
            // For now, if bulk assign fails, the multiload request won't occur
            // If the multiload request fails, we should rollback the assignments
            // Currently bulk unassign only unassigns all trips in an assignment, which would
            // unassign more than the trips we're operating on which would be annoying for user
            try {
              const tripIdsToAssign = uniq([
                ...selectedTripIdsToMultiload,
                originatingTripId,
              ]);
              if (shouldValidate) {
                const validationResults = await bulkValidate({
                  bulkTripValidate: {
                    trip_ids: tripIdsToAssign,
                    assignment_id: assignmentIdToMultiload,
                    is_multiload: true,
                    ordered_trip_location_detail_ids:
                      orderedTripLocationDetailIds.map(
                        (orderedTripLocationDetail) =>
                          orderedTripLocationDetail.trip_location_detail_id
                      ),
                  },
                }).unwrap();

                if (validationResults.invalid_trips?.length) {
                  // Let the rest of the component know what the results are
                  // We'll handle rendering the messages below
                  setBulkTripValidateResults(validationResults);
                  // Throw so that the execTripMenuAction doesn't think it succeeded
                  throw new Error('Bulk assignment failed due to validation');
                }
              }
              const result = await bulkAssign({
                bulkTripAssign: {
                  // The bulkAssign ep is smart enough to ignore trips that are already assigned
                  // Not sure about the bulk assign validate. That's for a future pr and shouldn't
                  // affect this anyway if the user chooses to override
                  // The uniq here is for completeness -- the ep actually ignores dupes already
                  trip_ids: tripIdsToAssign,
                  assignment_id: assignmentIdToMultiload,
                },
              }).unwrap();
              // Bulk unassign sends null on success
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              if (result && 'errors' in result) {
                setServerError(formatServerError(result));
                throw new Error('Bulk assign failed');
              }
            } catch (error) {
              if (
                !(
                  isError(error) &&
                  error.message === 'Bulk assignment failed due to validation'
                )
              ) {
                setServerError(formatServerError(error));
              }
              setIsLoading(false);
              // Rethrow so that execTripMenuAction doesn't think it succeeded
              throw error;
            }

            // Check if there's an existing multiload and patch it if so
            // This will fail if there are multiple multiloads that the user has brought in
            // We'll need to handle that eventually I think? The backend doesn't have validation
            // since that's in progress, but once that's done it may be handled
            const existingMultiLoadId = orderedMultiLoadItems.find(
              (orderedTripItem) => orderedTripItem.trip.multiload_id
            )?.trip.multiload_id;
            if (existingMultiLoadId) {
              try {
                const result = await updateTripMultiload({
                  id: existingMultiLoadId,
                  patchedMultiload: {
                    multiload_trip_location_details:
                      orderedTripLocationDetailIds,
                  },
                }).unwrap();
                if ('errors' in result) {
                  setServerError(formatServerError(result));
                  throw new Error('Multiload create failed');
                }
              } catch (error) {
                setServerError(formatServerError(error));
                // Rethrow so that execTripMenuAction doesn't think it succeeded
                throw error;
              } finally {
                setIsLoading(false);
              }
            } else {
              try {
                const result = await createTripMultiload({
                  multiload: {
                    multiload_trip_location_details:
                      orderedTripLocationDetailIds,
                  },
                }).unwrap();
                if ('errors' in result) {
                  setServerError(formatServerError(result));
                  throw new Error('Multiload create failed');
                }
              } catch (error) {
                setServerError(formatServerError(error));
                // Rethrow so that execTripMenuAction doesn't think it succeeded
                throw error;
              } finally {
                setIsLoading(false);
              }
            }

            dispatch(dispatcherSlice.actions.onMultiloadFlowSuccess());
          },
          successMessage: 'Trips multi-loaded!',
          errorMessage: null,
        })
      );
    },
    [
      unassignedTrips,
      assignedTrips,
      bulkTripValidateResults,
      orderedMultiLoadItems,
      dispatch,
      bulkValidate,
      assignmentIdToMultiload,
      bulkAssign,
      selectedTripIdsToMultiload,
      originatingTripId,
      updateTripMultiload,
      createTripMultiload,
    ]
  );

  if (!unassignedTrips) {
    return null;
  }

  return (
    <div className="flex min-w-[425px] max-w-md flex-col gap-4 p-2">
      {bulkTripValidateResults?.invalid_trips?.length ? (
        <FormattedBulkTripValidationError
          bulkTripValidateResults={bulkTripValidateResults}
          tripIdMetaMap={orderedMultiLoadItems.reduce((acc, orderItem) => {
            return {
              ...acc,
              [orderItem.trip.id]: {
                passengerFullName: orderItem.trip.passenger.full_name,
              },
            };
          }, {})}
        />
      ) : (
        <div className="flex flex-col gap-5">
          <div className="mt-3 flex justify-center">
            <MultiloadIcon />
          </div>
          <h2 className="-mt-2 text-center text-xl">
            Drag and drop to reorder
          </h2>
          {serverError && <FormErrorMessage>{serverError}</FormErrorMessage>}
          <div
            className={`mt-2 max-h-[50vh] overflow-auto ${
              isLoading ? 'disabled:cursor-not-allowed disabled:opacity-50' : ''
            }`}
          >
            <VerticalSortableDragAndDropList
              items={orderedMultiLoadItems}
              renderItem={(orderItem) => (
                <TripCard
                  trip={orderItem.trip}
                  hideMenu
                  variant={orderItem.type}
                />
              )}
              onReorder={setOrderedMultiLoadItems}
            />
          </div>
        </div>
      )}
      <div className="mt-4 flex gap-4">
        <Button
          className="flex-grow"
          onClick={() =>
            dispatch(dispatcherSlice.actions.onMultiloadFlowCancel())
          }
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          loading={isLoading}
          className="flex-grow"
          variant={
            bulkTripValidateResults?.invalid_trips?.length
              ? 'primary-danger'
              : 'primary'
          }
          disabled={isLoading}
          onClick={() =>
            onAssignClick({
              shouldValidate: !bulkTripValidateResults?.invalid_trips?.length,
            })
          }
        >
          {bulkTripValidateResults?.invalid_trips?.length
            ? 'Override and Save'
            : 'Save'}
        </Button>
      </div>
    </div>
  );
}

interface BulkValidationTripMeta {
  passengerFullName: string;
}

function FormattedBulkTripValidationError({
  bulkTripValidateResults,
  tripIdMetaMap,
}: {
  bulkTripValidateResults: BulkTripValidateResults;
  tripIdMetaMap: Record<string, BulkValidationTripMeta>;
}) {
  const formattedErrors =
    bulkTripValidateResults.invalid_trips?.map((invalidTrip) => {
      const tripMeta = tripIdMetaMap[invalidTrip.trip_id];
      const explanation = `${tripMeta.passengerFullName} - ${
        invalidTrip.explanation?.join('\n ') ?? ''
      }`;
      return explanation;
    }) || [];
  return (
    <FormErrorMessage>
      {/* 
        This is how we display these elsewhere, but we may want to give each its own
        FormErrorMessage instance for some separation
      */}
      <div className="pl-3">
        <BulletList items={formattedErrors} />
      </div>
    </FormErrorMessage>
  );
}
