import { useState } from 'react';

import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';

import { RootState } from 'src/app/store';
import { convertISODateTimePartsToUTCISO } from 'src/common/date/convertISODateTimePartsToUTCISO';
import { FormField } from 'src/common/FormField';
import { Button } from 'src/common/primitives/Button';
import { ArrowIcon } from 'src/common/primitives/icon/ArrowIcon';

import { useTripMenuActions } from './useTripMenuActions';

export function ChangeTripTime({ tripId }: { tripId: string }) {
  const menuActions = useTripMenuActions();
  const [newTime, setNewTime] = useState('');
  const trip = useSelector((state: RootState) => {
    // TODO: Intentionally leaving some ugly here
    // Normalizing the state would make this a lot cleaner as well as O(1) instead of O(n)
    return (
      state.dispatcher.dispatchResponse?.assigned_trips.find(
        (trip) => trip.id === tripId
      ) ||
      state.dispatcher.dispatchResponse?.unassigned_trips.find(
        (trip) => trip.id === tripId
      )
    );
  });

  if (!trip) return <div>No trip found for {tripId}</div>;
  return (
    <div className="flex flex-col gap-4 p-2">
      <div className="flex items-center gap-4">
        <FormField
          label="Current time"
          inputProps={{
            id: 'current-time',
            value: DateTime.fromISO(
              trip.scheduled_pickup_at || ''
            ).toLocaleString(DateTime.TIME_24_SIMPLE),
          }}
          type="time"
        />
        <ArrowIcon />
        <FormField
          label="New time"
          // TODO: Time fields need to be moved away from inputProps
          inputProps={{
            id: 'new-time',
            value: newTime,
          }}
          type="time"
          value={newTime}
          onChange={setNewTime}
        />
      </div>
      <Button
        variant="primary"
        onClick={() =>
          menuActions.updatePickupTime({
            tripId,
            newTime: convertISODateTimePartsToUTCISO({
              date: trip.scheduled_pickup_at,
              time: newTime,
            }),
          })
        }
      >
        Confirm
      </Button>
    </div>
  );
}
