import { useFormContext } from 'react-hook-form';

import { FormValues, Trip } from '../FormValues';

export function useMakeRoundTrip({ isWillCall }: { isWillCall: boolean }) {
  const { getValues, setValue } = useFormContext<FormValues>();

  return () => {
    const formData = getValues();
    const { is_round_trip, trips } = formData;
    if (is_round_trip) return;

    setValue('is_round_trip', !is_round_trip);
    const firstLeg = trips[0];
    const returnLeg: Trip = {
      pickupLocation: {
        ...firstLeg.dropoffLocation,
      },
      dropoffLocation: {
        ...firstLeg.pickupLocation,
      },
      timing: {
        date: firstLeg.timing.date,
        appointment_time: '',
        pickup_time: isWillCall ? '23:59' : '',
        is_will_call: isWillCall,
      },
      dispatcher_notes: firstLeg.dispatcher_notes,
      trip_tags: firstLeg.trip_tags,
      external_trip_id: '',
      input_price_cents: null,
      is_price_locked: false,
      load_time_seconds: 0,
      unload_time_seconds: 0,
      is_wait_and_return_outbound: false,
      is_wait_and_return_inbound: false,
    };
    setValue('trips', [...trips, returnLeg]);
  };
}
