import { useEffect, useState } from 'react';

import { TripEventRead } from 'src/common/external/bambi-api/bambiApi';
import { useLazyGetBulkTripEventsQuery } from 'src/common/external/bambi-api/emptyApi';

export function useFetchBulkTripEvents(tripIds: string[]) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<Record<string, TripEventRead[]> | null>(
    null
  );
  const [fetchBulkTripEvents] = useLazyGetBulkTripEventsQuery();

  useEffect(() => {
    fetchBulkTripEvents({ trip_ids: tripIds })
      .unwrap()
      .then((events) => {
        const eventDataMap: Record<string, TripEventRead[]> = {};
        events.forEach((e) => {
          if (!e.trip_id) {
            return;
          }

          if (!Array.isArray(eventDataMap[e.trip_id])) {
            eventDataMap[e.trip_id] = [];
          }

          eventDataMap[e.trip_id].push(e);
        });

        setData(eventDataMap);
        setError(null);
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fetchBulkTripEvents, tripIds]);

  return {
    loading,
    data,
    error,
  };
}
