import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/app/store';
import {
  usePricingPricingModelsCreateMutation,
  usePricingPricingModelsPartialUpdateMutation,
} from 'src/common/external/bambi-api/bambiApi';
import { Button } from 'src/common/primitives/Button';
import { formatRTKQueryError } from 'src/common/util/formatRTKQueryError';
import {
  onPricingModelDetailsEdit,
  onPricingModelError,
} from 'src/features/pricingModel/pricingModel.slice';

export function SavePriceModelButton() {
  const navigate = useNavigate();
  const pricingModelDetailsDraft = useAppSelector((state) => {
    return state.pricingModel.pricingModelDetailsDraft;
  });
  const dispatch = useAppDispatch();
  const [createPricingModel, createPricingModelState] =
    usePricingPricingModelsCreateMutation();
  const [updatePricingModel, updatePricingModelState] =
    usePricingPricingModelsPartialUpdateMutation();
  return (
    <Button
      disabled={
        !pricingModelDetailsDraft?.name ||
        createPricingModelState.isLoading ||
        updatePricingModelState.isLoading
      }
      className="flex gap-1"
      onClick={async () => {
        if (!pricingModelDetailsDraft) return;
        try {
          if (typeof pricingModelDetailsDraft.id === 'string') {
            const updateModelResponse = await updatePricingModel({
              id: pricingModelDetailsDraft.id,
              patchedPricingModel: {
                ...pricingModelDetailsDraft,
              },
            });
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if ('data' in updateModelResponse && updateModelResponse.data?.id) {
              navigate(
                `/pricing/pricing-models/${updateModelResponse.data.id}`
              );
              dispatch(onPricingModelDetailsEdit(null));
            } else {
              const formattedError =
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                'error' in updateModelResponse && updateModelResponse.error
                  ? formatRTKQueryError(updateModelResponse.error)
                  : 'An error occurred while updating the pricing model';
              dispatch(onPricingModelError(formattedError));
            }
          } else {
            const newModelResponse = await createPricingModel({
              pricingModel: {
                ...pricingModelDetailsDraft,
              },
            });
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if ('data' in newModelResponse && newModelResponse.data?.id) {
              navigate(`/pricing/pricing-models/${newModelResponse.data.id}`);
              dispatch(onPricingModelDetailsEdit(null));
            } else {
              const formattedError =
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                'error' in newModelResponse && newModelResponse.error
                  ? formatRTKQueryError(newModelResponse.error)
                  : 'An error occurred while updating the pricing model';
              dispatch(onPricingModelError(formattedError));
            }
          }
        } catch (e) {
          let message = 'An error occurred while updating the pricing model';
          // @ts-ignore
          if (e && typeof e.message === 'string') {
            // @ts-ignore
            message = e.message;
          } else if (e && typeof e === 'string') {
            message = e;
          }

          dispatch(onPricingModelError(message));
        }
      }}
      variant="primary"
    >
      Save
    </Button>
  );
}
