import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSearchParams } from 'react-router-dom';

import { VehiclesInspectionsListApiArg } from 'src/common/external/bambi-api/bambiApi';
import { clearFiltersExtraReducer } from 'src/common/redux/clearFiltersExtraReducer';
import { searchParamsToJSON } from 'src/common/util/searchParamsToJSON';

export interface IFleetInspectionState {
  inspectionListFilter: VehiclesInspectionsListApiArg;
  showInspectionDownloadModal: boolean;
}

export const initialState: IFleetInspectionState = {
  inspectionListFilter: {},
  showInspectionDownloadModal: false,
};

export const fleetInspectionSlice = createSlice({
  name: 'fleetInspection',
  initialState,
  reducers: {
    clearFilters: (state) => {
      state.inspectionListFilter = initialState.inspectionListFilter;
    },
    onDriverFilterChange: (state, action) => {
      state.inspectionListFilter.membershipIn = action.payload;
    },
    onVehicleFilterChange: (state, action) => {
      state.inspectionListFilter.vehicleIn = action.payload;
    },
    onPassFailFilterChange: (state, action) => {
      state.inspectionListFilter.hasFail = action.payload;
    },
    onInspectionDateFilterChange: (state, action) => {
      state.inspectionListFilter.rangeStart = action.payload.rangeStart;
      state.inspectionListFilter.rangeEnd = action.payload.rangeEnd;
    },
    onClearDateFilter: (state) => {
      delete state.inspectionListFilter.rangeStart;
      delete state.inspectionListFilter.rangeEnd;
    },
    // Translates ?search into state
    initializeFilters(state) {
      const currentParams = searchParamsToJSON(
        new URLSearchParams(window.location.search)
      );

      Object.keys(currentParams).forEach((param) => {
        const rawValue = currentParams[param];
        switch (param) {
          case 'Driver':
            state.inspectionListFilter.membershipIn = rawValue as string[];
            break;
          case 'Vehicle':
            state.inspectionListFilter.vehicleIn = rawValue as string[];
            break;
          case 'PassFail':
            state.inspectionListFilter.hasFail =
              (rawValue as string) === '1' ? true : undefined;
            break;
          case 'InspectionDate': {
            const date = JSON.parse(rawValue as string);
            state.inspectionListFilter.rangeStart = date.rangeStart;
            state.inspectionListFilter.rangeEnd = date.rangeEnd;
            break;
          }
        }
      });
    },
    setInspectionDownloadModal: (state, action: PayloadAction<boolean>) => {
      state.showInspectionDownloadModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    clearFiltersExtraReducer(builder);

    // Translates setFilterX payload into ?search
    builder.addMatcher(
      (action) => {
        const type: string = action.type;
        return type.includes('FilterChange');
      },
      (state, action: PayloadAction) => {
        const type: string = action.type;
        const payload: unknown = action.payload;
        const filterParam = type.match(/on([a-z]+)FilterChange/i)?.[1];

        if (!filterParam) {
          return state;
        }

        const value: string | string[] | undefined = (() => {
          switch (filterParam) {
            case 'Driver':
            case 'Vehicle':
              return payload as string[];
            case 'PassFail':
              return payload ? '1' : '0';
            case 'InspectionDate':
              return JSON.stringify(payload);
          }
        })();

        if (value) {
          const currentParams = searchParamsToJSON(
            new URLSearchParams(window.location.search)
          );
          const nextParams = createSearchParams({
            ...currentParams,
            [filterParam]: value,
          });

          window.history.pushState(
            null,
            '',
            `${window.location.pathname}?${nextParams.toString()}`
          );
        }

        return state;
      }
    );
  },
});
