import { useFormContext } from 'react-hook-form';

import { FormValues, Trip } from '../FormValues';

export function useAdditionalLeg() {
  const { getValues, setValue } = useFormContext<FormValues>();

  return () => {
    const formData = getValues();
    const { trips } = formData;

    const lastLeg = trips[trips.length - 1];
    const additionalLeg: Trip = {
      pickupLocation: {
        ...lastLeg.dropoffLocation,
      },
      dropoffLocation: {
        address: { label: '', value: '' },
        address_details: '',
        stairs: 0,
        contact_name: '',
        contact_phone_number: '',
        driver_notes: '',
        stair_equipment: '',
      },
      timing: {
        date: lastLeg.timing.date,
        appointment_time: '',
        pickup_time: '',
        is_will_call: false,
      },
      dispatcher_notes: '',
      trip_tags: lastLeg.trip_tags,
      external_trip_id: '',
      input_price_cents: null,
      is_price_locked: false,
      load_time_seconds: 0,
      unload_time_seconds: 0,
      is_wait_and_return_outbound: false,
      is_wait_and_return_inbound: false,
    };
    setValue('trips', [...trips, additionalLeg]);
  };
}
