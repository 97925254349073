import { useEffect } from 'react';

import { usePDF } from '@react-pdf/renderer';

import { TripEventRead } from 'src/common/external/bambi-api/bambiApi';
import { useAuth } from 'src/features/auth/useAuth';

import { MASReportRecord } from '../../../management/download/details/mas/types';
import { useMASReportContext } from '../MASReportContext';
import { MASReportDocument } from './MASReportDocument';

interface MASReportRendererProps {
  trips: MASReportRecord[];
  tripEvents: Record<string, TripEventRead[]>;
  onDocumentReady: (blob: Blob | null) => void;
}

/**
 * Renders the report document using react-pdf, triggers onDocumentReady after
 * the pdf finishes rendering as a blob.
 */
export function MASReportRenderer({
  trips,
  tripEvents,
  onDocumentReady,
}: MASReportRendererProps) {
  const { hasMapData, generateMapStructure, mapData } = useMASReportContext();
  const newMapData = generateMapStructure(trips);
  const [instance, updatePdf] = usePDF();
  const { currentOrganizationName, currentOrganizationSettings } = useAuth();

  useEffect(() => {
    if (!hasMapData(newMapData)) {
      return;
    }

    updatePdf(
      <MASReportDocument
        trips={trips}
        tripEvents={tripEvents}
        mapData={mapData}
        companyName={currentOrganizationName}
        companyLogoUrl={currentOrganizationSettings?.logo}
      />
    );
  }, [
    hasMapData,
    newMapData,
    trips,
    updatePdf,
    mapData,
    currentOrganizationName,
    currentOrganizationSettings?.logo,
    tripEvents,
  ]);

  useEffect(() => {
    if (hasMapData(newMapData) && !instance.loading && instance.blob) {
      onDocumentReady(instance.blob);
    }
  }, [
    hasMapData,
    instance.blob,
    instance.loading,
    newMapData,
    onDocumentReady,
  ]);

  return null;
}
